import { all, takeEvery, put, call  } from 'redux-saga/effects'
// import { notification } from 'antd'
import { getFeedbacks, } from '../service'
import actions from './actions'

export function* GET_FEEDBACKS({ payload }) {  
    yield put({
      type: 'feedbacks/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const data =  yield call(getFeedbacks , payload);
    
    if (data.status) {   
        const { feedbacks } = data.data;   
      yield put({
        type: 'feedbacks/SET_STATE',
        payload: {
          feedbacks,
          pagination: {
            total: data.data.totalDocs,
            current: data.data.page,
            pageSize: data.data.limit,
            },
          loading: false,
        }
      })
    } else {
      yield put({
        type: 'feedbacks/SET_STATE',
        payload: {
          feedbacks:[] ,
          loading: false,
        }
      })
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_FEEDBACKS, GET_FEEDBACKS),  
    ])
  }
  