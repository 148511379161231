import { all, takeEvery, put, call  } from 'redux-saga/effects'
import { notification } from 'antd'
import i18n from "i18next";
import { getCustomers, banCustomer, syncCustomers } from '../service'
import actions from './actions'

export function* SYNC_CUSTOMERS({ payload }) {
  yield put({
    type: 'customers/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(syncCustomers, payload)

  if (data.status) {
    yield put({
      type: 'customers/SET_STATE',
      payload: {
        loading: false,
        newCustomers: data.customersCount
      },
    })
    yield put({
      type: 'customers/GET_CUSTOMERS',
      payload:{
        query: {},
        options: {
          page: 1,
          limit: 10,
          sort: { created_at: -1 },
        },
        businessId: payload.businessId
      }
    })
  } else {
    yield put({
      type: 'customers/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 500,
      description: i18n.t('loyaltyProgram:notification.syncError'),
      duration: 3
    })
  }
}

export function* GET_CUSTOMERS({ payload }) {  
    yield put({
      type: 'customers/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const data =  yield call(getCustomers , payload);
    
    if (data.status) {   
        const { customers } = data.data;   
      yield put({
        type: 'customers/SET_STATE',
        payload: {
          customers,
          pagination: {
            total: data.data.totalDocs,
            current: data.data.page,
            pageSize: data.data.limit,
          },
          loading: false,
          numOfMembers: data.data.numOfMembers,
        }
      })
    } else {
      yield put({
        type: 'customers/SET_STATE',
        payload: {
          customers:[] ,
          loading: false,
          numOfMembers: data.response.data.numOfMembers,
        }
      })
    }
}

export function* BAN_CUSTOMER({ payload }) {  
    const data =  yield call(banCustomer , payload);
    if (data.status) {  
      yield put({
        type: 'customers/UPDATE_CUSTOMER',
        payload: {
          id: payload.id,
          banned: payload.banned
        }
      }) 
      notification.success({
        message: 200,
        description: i18n.t(`customers:notification.banned${payload.banned}`),
        duration: 3
      })
    } else {
      notification.warning({
        message: 500,
        description: i18n.t(`customers:notification.bannederror`),
        duration: 3
      })
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_CUSTOMERS, GET_CUSTOMERS),  
        takeEvery(actions.BAN_CUSTOMER, BAN_CUSTOMER),
        takeEvery(actions.SYNC_CUSTOMERS, SYNC_CUSTOMERS)
    ])
  }
  