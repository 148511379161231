import cookie from 'js-cookie'
import axios from 'axios'
import { API_ADDRESS } from '../../services/config'

/* eslint-disable import/prefer-default-export */

export async function getCustomer(params) {
  try {
    const { data } = await axios.get(`${API_ADDRESS}/members/${params.businessId}/${params.id}`, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (error) {
    return error
  }
}
export async function banCustomer(params) {
  try {
    const { data } = await axios.post(
      `${API_ADDRESS}/businesses/${params.businessId}/customers/${params.id}/ban?ban=${params.banned}`,
      params,
      {
        headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
      },
    )
    return data
  } catch (error) {
    return error
  }
}
