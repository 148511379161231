import cookie from 'js-cookie'
import axios from 'axios'
import {API_ADDRESS  } from '../../services/config'


// branches managment 
export async function getBranches(params){
  try{
    const {
      data
    } = await axios.get(`${API_ADDRESS}/businesses/${params.businessId}/branches/${ (params && params.id) ? params.id : '' }`,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}

export async function addBranches(params){ 
  try{
    const { data } = await axios.post(`${API_ADDRESS}/businesses/${params.businessId}/branches`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch({response}){    
    return response;
  }
}

export async function updateBranch(params){  
  try{
    const { data } = await axios.patch(`${API_ADDRESS}/businesses/${params.businessId}/branches/${params.id}`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });

    return data;
  }catch({response}){    
    return response;
  }
}

export async function deleteBranch(params){  
  try{
    const { data } = await axios.delete(`${API_ADDRESS}/businesses/${params.businessId}/branches/${params.id}`,
    { headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  }});
    return data;
  }catch({response}){
    return response;
  }
}

export async function syncBranches(params) {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/foodics/sync-branches`, params, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (response) {
    return response
  }
}