const actions = {
  SET_STATE: 'user/SET_STATE',
  RESET_STATE: 'user/RESET_STATE',
  LOGIN: 'user/LOGIN',
  LOGIN_OTP: 'user/LOGIN_OTP',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOAD_ACCOUNT_DATA: 'user/LOAD_ACCOUNT_DATA',
  LOGOUT: 'user/LOGOUT',
  PAYMENT: 'user/PAYMENT',
  REQUEST_RESET: 'user/REQUEST_RESET',
  RESET: 'user/RESET',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  CLOSE_ALERT: 'user/CLOSE_ALERT',
  UPDATE_USER: 'user/UPDATE_USER',
  UPDATE_FCM_TOKEN: 'user/UPDATE_FCM_TOKEN',
  GET_ONBOARDING_STATUS: 'user/GET_ONBOARDING_STATUS',
  UPDATE_ONBOARDING_STATUS: 'user/UPDATE_ONBOARDING_STATUS',
  REQUEST_PLAN_UPGRADE: 'user/REQUEST_PLAN_UPGRADE',
}

export default actions
