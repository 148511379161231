import { all, takeEvery, put, call  } from 'redux-saga/effects'
import { notification } from 'antd'
import i18n from "i18next"
import { getRewards, deleteReward, updateReward, newReward } from '../service'
import actions from './actions'



export function* GET_REWARDS({ payload }) {  
    yield put({
      type: 'rewards/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const data =  yield call(getRewards , payload);    
    if (data.status) {   
        const rewards = data.data;   
      yield put({
        type: 'rewards/SET_STATE',
        payload: {
          rewards,
          loading: false,
        }
      })
    } else {
      yield put({
        type: 'rewards/SET_STATE',
        payload: {
          rewards:[] ,
          loading: false,
        }
      })
    }
    
}

export function* UPDATE_REWARD({ payload }) {  
    yield put({
      type: 'rewards/SET_STATE',
      payload: {
        creating: true
      }
    })
    const data =  yield call(updateReward , payload);
    if (data.status) {
      yield put({
        type: 'rewards/SET_STATE',
        payload: {
          creating: false
        }
      })
      yield put({
        type: 'rewards/GET_REWARDS',
        payload:{
          businessId: payload.businessId
        }
      })
      notification.success({
        message: 200,
        description: i18n.t('customerRewards:notification.rewardUpdateTrue'),
        duration: 3
      })
  } else {
    yield put({
      type: 'rewards/SET_STATE',
      payload: {
        creating: false,
      }
    })
    notification.warning({
      message: 500,
      description: i18n.t('customerRewards:notification.rewardUpdateFalse'),
      duration: 3
    })
  } 
}

export function* NEW_REWARD({ payload }) {  
    yield put({
      type: 'rewards/SET_STATE',
      payload: {
        loading: true,
      }
    })
    const data =  yield call(newReward , payload);
    if (data.status === true) {
        yield put({
          type: 'rewards/SET_STATE',
          payload: {
            loading: false
          }
        })
        notification.success({
          message: 200,
          description: i18n.t('customerRewards:notification.rewardAddTrue'),
          duration: 3
        })
        yield put({
          type: 'rewards/GET_REWARDS',
          payload:{
            businessId: payload.businessId
          }
        })
    } else {
      yield put({
        type: 'rewards/SET_STATE',
        payload: {
          loading: false,
        }
      })
      notification.warning({
        message: 500,
        description: i18n.t('customerRewards:notification.rewardAddFalse'),
        duration: 3
      })
    }
}

export function* GET_SINGLE_REWARD({ payload }) {  
    const data =  yield call(getRewards , payload);
    if (data.status) {    
      yield put({
        type: 'rewards/SELECTED_REWARD', 
        payload: {
          selected: data.data.reward,
        }
        })
        if(payload){
          payload.func()
        }
    } else {
      notification.warning({
        message: 500,
        description: i18n.t('customerRewards:notification.wrong'),
        duration: 3
      })
    }
    
}

export function* DELETE_REWARD({ payload }){
  yield put({
    type: 'rewards/SET_STATE',
    payload: {
      loading: true  
    }
  })
    const response =  yield call(deleteReward , payload);
    if (response.status) {  
      yield put({
        type: 'rewards/SET_STATE',
        payload: {
          loading: false
        }
      })
      yield put({
        type: 'rewards/GET_REWARDS',
        payload:{
          businessId: payload.businessId
        }
      })
      notification.success({
        message: 200,
        description: i18n.t('customerRewards:notification.rewardDeleteTrue'),
        duration: 3
      })
    } else {
      notification.warning({
        message: 500,
        description: i18n.t('customerRewards:notification.rewardDeleteFalse'),
        duration: 3
      })
    }
    
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_REWARDS, GET_REWARDS),  
        takeEvery(actions.DELETE_REWARD, DELETE_REWARD), 
        takeEvery(actions.GET_SINGLE_REWARD, GET_SINGLE_REWARD), 
        takeEvery(actions.UPDATE_REWARD, UPDATE_REWARD), 
        takeEvery(actions.NEW_REWARD, NEW_REWARD),
    ])
  }
  