const actions = {
  SET_STATE: 'transactions/SET_STATE',
  RESET_STATE: 'transactions/RESET_STATE',
  GET_TRANSACTIONS: 'transactions/GET_TRANSACTIONS',
  GET_UNCLAIMED_TRANSACTIONS: 'transactions/GET_UNCLAIMED_TRANSACTIONS',
  GET_UNCLAIMED_TRANSACTION: 'transactions/GET_UNCLAIMED_TRANSACTION',
  APPROVE_TRANSACTION: 'transactions/APPROVE_TRANSACTION',
}

export default actions
