const actions = {
    SET_STATE: 'loyalty/SET_STATE',
    RESET_STATE: 'loyalty/RESET_STATE',
    SET_LOYALTY_ATTR: 'loyalty/SET_LOYALTY_ATTR',
    GET_LOYALTY: 'loyalty/GET_LOYALTY',
    UPDATE_LOYALTY: 'loyalty/UPDATE_LOYALTY',
    GET_LEVELS: 'loyalty/GET_LEVELS',
    NEW_LEVEL: 'loyalty/NEW_LEVEL',
    ADD_LEVEL_ELEM: 'loyalty/ADD_LEVEL_ELEM',
    UPDATE_LEVEL: 'loyalty/UPDATE_LEVEL',
    DELETE_LEVEL: 'loyalty/DELETE_LEVEL',
    GET_STAMP_CARDS: 'loyalty/GET_STAMP_CARDS',
    NEW_STAMP_CARD: 'loyalty/NEW_STAMP_CARD',
    UPDATE_STAMP_CARD: 'loyalty/UPDATE_STAMP_CARD',
    DELETE_STAMP_CARD: 'loyalty/DELETE_STAMP_CARD',
    REMOVE_STAMP_CARD: 'loyalty/REMOVE_STAMP_CARD',
    SET_SELECTED_STAMP_CARD: 'loyalty/SET_SELECTED_STAMP_CARD',
    GET_HAPPY_HOURS: 'loyalty/GET_HAPPY_HOURS', 
    DELETE_HAPPY_HOUR: 'loyalty/DELETE_HAPPY_HOUR', 
    UPDATE_HAPPY_HOUR: 'loyalty/UPDATE_HAPPY_HOUR', 
    NEW_HAPPY_HOUR: 'loyalty/NEW_HAPPY_HOUR',
}

export default actions