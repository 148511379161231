module.exports = {
  API_ADDRESS :  process.env.REACT_APP_APP_API_ADDRESS || 'http://localhost:5000/api/v1', 
  ENROLLMENT_APP_ADDRESS: process.env.REACT_APP_ENROLLMENT_APP_ADDRESS  ||  'http://localhost:8000',
  GOOGLE_MAP_KEY :  process.env.REACT_APP_GOOGLE_MAP_KEY,
  FOODICS_CLIENT_ID :  process.env.REACT_APP_FOODICS_CLIENT_ID,
  FOODICS_API :  process.env.REACT_APP_FOODICS_API || 'https://api.foodics.com/',
  FOODICS_DASHBOARD :  process.env.REACT_APP_FOODICS_DASHBOARD,
  VAPI_KEY: process.env.REACT_APP_VAPI_KEY,
  FIREBASE_API_KEY:process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_SENDER_ID:process.env.REACT_APP_FIREBASE_SENDER_ID,
  FIREBASE_APP_ID:process.env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID:process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  GTM_ID: process.env.REACT_APP_GTM_ID,
  NEW_FOOTER: process.env.REACT_APP_NEW_FOOTER,
}
