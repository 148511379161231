import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import i18n from "i18next"
import { getTransactions, getUnclaimedTransactions, approveTransaction } from '../service'
import actions from './actions'

export function* GET_TRANSACTIONS({ payload }) {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(getTransactions, payload)

  if (data.status) {
    const { transactions } = data.data
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        transactions,
        pagination: {
          total: data.data.totalDocs,
          current: data.data.page,
          pageSize: data.data.limit,
        },
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        transactions: [],
        loading: false,
      },
    })
  }
}

export function* GET_UNCLAIMED_TRANSACTIONS({ payload }) {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loadingUnclaimed: true,
    },
  })
  const data = yield call(getUnclaimedTransactions, payload)

  if (data.status) {
    const { transactions } = data.data
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        transactionsUnclaimed: transactions,
        paginationUnclaimed: {
          total: data.data.totalDocs,
          current: data.data.page,
          pageSize: data.data.limit,
        },
        loadingUnclaimed: false,
      },
    })
  } else {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        transactionsUnclaimed: [],
        loadingUnclaimed: false,
      },
    })
  }
}

export function* GET_UNCLAIMED_TRANSACTION({ payload }) {
  const data = yield call(getUnclaimedTransactions, payload)
  if (data.status) {
    const transaction = data.data
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        transactionUnclaimed: transaction,
      },
    })
  } else {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        transactionUnclaimed: null,
      },
    })
  }
}

export function* APPROVE_TRANSACTION({ payload }) {
  // const data =  yield call(approveTransaction , payload);
  // if (data.status) {
  //     const  transaction = data.data;
  //   yield put({
  //     type: 'transactions/SET_STATE',
  //     payload: {
  //       transactionUnclaimed: transaction,
  //     }
  //   })
  // } else {
  //   yield put({
  //     type: 'transactions/SET_STATE',
  //     payload: {
  //       transactionUnclaimed: null
  //     }
  //   })
  // }
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loadingUnclaimed: true,
    },
  })
  const data = yield call(approveTransaction, payload)
  if (data.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loadingUnclaimed: false,
      },
    })

    yield put({
      type: 'transactions/GET_UNCLAIMED_TRANSACTIONS',
      payload: {
        options: {
          page: 1,
          limit: 10,
          sort: { created_at: -1 },
        },
        businessId: payload.businessId,
      },
    })
    notification.success({
      message: 200,
      description: i18n.t(`transactions:notification.claim${payload.approved}`),
      duration: 3,
    })
  } else {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loadingUnclaimed: false,
      },
    })
    notification.warning({
      message: 500,
      description: i18n.t('transactions:notification.claimError'),
      duration: 3,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TRANSACTIONS, GET_TRANSACTIONS),
    takeEvery(actions.GET_UNCLAIMED_TRANSACTIONS, GET_UNCLAIMED_TRANSACTIONS),
    takeEvery(actions.GET_UNCLAIMED_TRANSACTION, GET_UNCLAIMED_TRANSACTION),
    takeEvery(actions.APPROVE_TRANSACTION, APPROVE_TRANSACTION),
  ])
}
