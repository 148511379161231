import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, withRouter } from 'react-router-dom'
import styles from './style.module.scss'

const mapStateToProps = ({ user, settings }) => ({ user, direction: settings.direction })

const ProfileMenu = ({ dispatch, user, direction, location: { pathname } }) => {
  const logout = (e) => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }
  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />
          {direction === 'rtl' ? '،' : ','} {user.username || 'Anonymous'}
        </strong>
        {/* <div>
          <strong className="mr-1">
            <FormattedMessage id="topBar.profileMenu.billingPlan" />:{' '}
          </strong>
          Professional
        </div> */}
        {/* <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.role" />:{' '}
          </strong>
          {user.role || '—'}
        </div> */}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.email" />:{' '}
          </strong>
          {user.email || '—'}
          <br />
          <strong>
            <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
          </strong>
          {user?.phone?.number && user?.phone?.countryCode
            ? `${user?.phone?.countryCode}${user?.phone?.number}`
            : '—'}
        </div>
      </Menu.Item>
      {!/^\/start/i.test(pathname) && (
        <>
          <Menu.Divider />
          <Menu.Item>
            <Link to="/settings/account">
              <FontAwesomeIcon
                className={`${direction === 'rtl' ? 'ml-2' : 'mr-2'}`}
                icon={['far', 'user-circle']}
              />
              <FormattedMessage id="topBar.profileMenu.editProfile" />
            </Link>
          </Menu.Item>
        </>
      )}
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <FontAwesomeIcon
            className={`${direction === 'rtl' ? 'ml-2' : 'mr-2'}`}
            icon={['far', 'sign-out']}
          />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <Avatar
          className={styles.avatar}
          src={user.photoURL}
          shape="square"
          size="large"
          icon={<UserOutlined />}
        />
      </div>
    </Dropdown>
  )
}

export default withRouter(connect(mapStateToProps)(ProfileMenu))
