/* eslint-disable no-underscore-dangle */
/* eslint-disable no-case-declarations */
import actions from './actions'

const initialState = {
    branches: [],
    loading: false,
    selected: null
}

export default function branchesReducer(state = initialState, action) {  
    switch (action.type) {
      case actions.SET_STATE:
        return { ...state, ...action.payload }
      case actions.RESET_STATE:
        return { ...initialState }
      case actions.SELECTED_BRANCH:
        let {selected} = state;
        selected = { ...selected , ...action.payload.selected }
      return {
        ...state,
        selected: {...selected},
        }
      case actions.ADD_BRANCH_ELEM:
        return {
          ...state,
          creating: action.payload.creating,
          branches: [{...action.payload.branch}, ...state.branches],
        }
      case actions.DELETE_BRANCH_ELEM:
        const newBranches = state.branches.filter(elm => elm._id !== action.payload.id)
        return {
          ...state,
          loading: action.payload.loading,
          branches: newBranches,
        }
      default:
        return state
    }
  }