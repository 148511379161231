import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import rewards from '../pages/rewards/redux/sagas'
import customers from '../pages/customers/redux/sagas'
import member from '../pages/single-customer/redux/sagas'
import transactions from '../pages/transactions/redux/sagas'
import loyalty from '../pages/loyalty-program/redux/sagas'
import business from '../pages/settings/redux/sagas'
import stats from '../pages/dashboard/redux/sagas'
import branch from '../pages/branches/redux/sagas'
import feedbacks from '../pages/feedback/redux/sagas'
import wallets from '../pages/wallet/redux/sagas'
import products from '../pages/products/redux/sagas'
import integrations from '../pages/integration/redux/sagas'
import campaigns from '../pages/campaigns/redux/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    member(),
    menu(),
    settings(),
    stats(),
    customers(),
    transactions(),
    feedbacks(),
    branch(),
    business(),
    loyalty(),
    rewards(),
    wallets(),
    products(),
    integrations(),
    campaigns(),
  ])
}
