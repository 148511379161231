/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import actions from './actions'

const initialState = {
  _id: '',
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  photoURL: '',
  defaultBusiness: '',
  role: '',
  authorized: false,
  isAdmin: false,
  team: [],
  paymentMethods: [],
  loading: false,
  updating: false,
  stats: {},
  history: [],
  subscription: {},
  plan: {},
  firstTime: false,
  userStatsLoading: false,
  userHistoryLoading: false,
  userPlansLoading: false,
  upgradeAlert: false,
  onBoardingState: {
    profile: {
      value: false,
      index: 0,
    },
    pos: {
      value: false,
      index: 1,
    },
    spots: {
      value: false,
      index: 2,
    },
    products: {
      value: false,
      index: 3,
    },
    loyalty: {
      value: false,
      index: 4,
    },
  },
  posSkipped: false,
  guideComplete: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.RESET_STATE:
      return { ...initialState }
    case actions.UPDATE_ONBOARDING_STATUS: {
      const { data } = action.payload
      const newOnBoardingState = state.onBoardingState
      Object.keys(data).forEach((item) => {
        newOnBoardingState[item].value = data[item] || false
      })
      const completed = Object.values(newOnBoardingState).every((item) => item.value === true)
      return { ...state, onBoardingState: newOnBoardingState, guideComplete: completed }
    }
    default:
      return state
  }
}
