import React from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import { I18nextProvider } from 'react-i18next'
import { connect } from 'react-redux'

import i18n from './i18n'

import english from './locales/en-US'
import arabic from './locales/ar-EG'

const locales = {
  'en-US': english,
  'ar-EG': arabic,
}

const mapStateToProps = ({ settings }) => ({ settings })

const Localization = ({ children, settings: { locale, direction }, dispatch }) => {
  const currentLocale = locales[locale]
  if (i18n.language !== locale.split('-')[0]) {
    i18n.changeLanguage(locale.split('-')[0])
    dispatch({
      type: 'menu/GET_DATA',
    })
  }
  return (
    <ConfigProvider locale={currentLocale.localeAntd} direction={direction}>
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
        <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
      </IntlProvider>
    </ConfigProvider>
  )
}

export default connect(mapStateToProps)(Localization)
