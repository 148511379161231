/* eslint-disable no-underscore-dangle */
import { all, takeEvery, put, call  } from 'redux-saga/effects'
import { notification } from 'antd'
import i18n from "i18next"
import { history } from 'index'
import {
  logout,
  loginToApi,
  getAccountDetails,
  renewAuthUser,
  signupToApi,
  requestReset,
  reset,
  updateUser,
  resetPassword,
  updateFCM,
  getOnBoarding,
  requestPlanUpgrade,
  checkOTP,
} from '../../services/user'
import actions from './actions'

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data =  yield call(loginToApi, email, password);
  if(!data){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return;
  }

  if(!data.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.open({
      message: i18n.t(`apiMessages:${data.key}.message`),
      description: i18n.t(`apiMessages:${data.key}.details`),
      duration: 5
    });
    return;
  }

  if( data.role === "OWNER"){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        authorized: true,
        ...data
      }
    })
    yield history.push('/')
    notification.success({
      message: i18n.t(`apiMessages:${data.key}.message`),
      description: i18n.t(`apiMessages:${data.key}.details`),
      duration: 3.5
    }) 
    yield put({
      type: 'user/LOAD_ACCOUNT_DATA',
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.open({
      message: i18n.t(`apiMessages:not_authenticated.message`),
      description: i18n.t(`apiMessages:not_authenticated.details`),
      duration: 5
    });
  }

}

export function* LOGIN_OTP({ payload }) {
  const { email, password, verificationCode } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data =  yield call(checkOTP, email, password, verificationCode);
  console.log({data})
  if(!data){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return;
  }

  if(!data.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.open({
      description: i18n.t(`apiMessages:incorrectOTP.message`),
      duration: 5
    });
    return;
  }

  if( data.role === "OWNER"){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        authorized: true,
        ...data
      }
    })
    yield history.push('/')
    notification.success({
      message: i18n.t(`apiMessages:${data.key}.message`),
      description: i18n.t(`apiMessages:${data.key}.details`),
      duration: 3.5
    }) 
    yield put({
      type: 'user/LOAD_ACCOUNT_DATA',
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.open({
      message: i18n.t(`apiMessages:not_authenticated.message`),
      description: i18n.t(`apiMessages:not_authenticated.details`),
      duration: 5
    });
  }

}

export function* LOADACCOUNTDATA(){
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data =  yield call(getAccountDetails);    
  if (data) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        ...data.data
      }
    })
  }
  // const planName = data.data.subscription.name
  // const plan = yield call(getPlan,planName)
  // if (plan) {
  //   yield put({
  //     type: 'user/SET_STATE',
  //     payload: {
  //       plan: plan.data ? {...plan.data} : {},
  //       userPlansLoading: false,
  //     }
  //   })
  // }
}

export function* REGISTER({ payload }) {
  const { email, password  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(signupToApi, email, password)
  if(!data){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return;
  }
  if (data.status){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })

    yield history.push('/auth/login')
    notification.success({
      message: i18n.t(`apiMessages:${data.key}.message`),
      description: i18n.t(`apiMessages:${data.key}.details`),
      duration: 5
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.open({
      message: i18n.t(`apiMessages:${data.key}.message`),
      description: i18n.t(`apiMessages:${data.key}.details`),
      duration: 5
    });
    // return;
    // yield put({
    //   type: 'user/SET_STATE',
    //   payload: {
    //     loading: false,
    //   },
    // })
    // notification.error({
    //   message: 500,
    //   description: response.message || response.data.message ,
    // })
  }
}

export function* REQUEST_RESET({payload}) {  
  const { email } = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(requestReset, email)
  if(!data){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return;
  }
  if(data.status) {
    notification.success({
      message: i18n.t(`apiMessages:${data.key}.message`),
      description: i18n.t(`apiMessages:${data.key}.details`),
      duration: 5,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })    
  } else {
    notification.error({
      message: i18n.t(`apiMessages:${data.key}.message`),
      description: i18n.t(`apiMessages:${data.key}.details`),
      duration: 5,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* RESET({payload}) {  
  const { password ,  resetPasswordToken } = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(reset, password,  resetPasswordToken)
  if(!data){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return;
  }
  if(data.status) {
    notification.success({
      message: i18n.t(`apiMessages:${data.key}.message`),
      description: i18n.t(`apiMessages:${data.key}.details`),
      duration: 3,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    }) 
    yield history.push('/auth/login')   
  } else {
    notification.success({
      message: i18n.t(`apiMessages:${data.key}.message`),
      description: i18n.t(`apiMessages:${data.key}.details`),
      duration: 3,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {  
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(renewAuthUser)
    if (!data || !data.status){
      yield call(logout)
      yield put({
        type: 'user/SET_STATE',
        payload: {
          id: '',
          name: '',
          email: '',
          photoURL: '',
          authorized: false,
          loading: false,
        },
      })
      return;
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        authorized: true,
        email: data.sub,  
        defaultBusiness: data.defaultBusiness,
        isAdmin: data.admin,
        _id: data._id,
      }
    })
    // yield history.push('/')
    yield put({
      type: 'user/LOAD_ACCOUNT_DATA',
    })
}

export function* LOGOUT() {
  yield call(logout)
  yield put({ type: 'user/RESET_STATE' })
  yield put({ type: 'unclaimedTransactions/RESET_STATE' })
  yield put({ type: 'transactions/RESET_STATE' })
  yield put({ type: 'business/RESET_STATE' })
  yield put({ type: 'rewards/RESET_STATE' })
  yield put({ type: 'loyalty/RESET_STATE' })
  yield put({ type: 'feedbacks/RESET_STATE' })
  yield put({ type: 'stats/RESET_STATE' })
  yield put({ type: 'customers/RESET_STATE' })
  yield put({ type: 'branch/RESET_STATE' })
  yield put({ type: 'wallets/RESET_STATE' })
}

export function* PAYMENT() {  
  const data =  yield call(getAccountDetails);  
  if(!data){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return;
  }
  if (data) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        ...data.data,
        upgradeAlert: true
      }
    })
  }
}

export function* CLOSE_ALERT(){
  yield put({
    type: 'user/SET_STATE',
    payload: {
      upgradeAlert: false
    }
  })
}

export function* UPDATE_USER({payload}){
  yield put({
    type: 'rewards/SET_STATE',
    payload: {
      updating: true
    }
  })
  const data =  yield call(updateUser , payload);
  if (data.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        updating: false,
        ...payload
      }
    })

    notification.success({
          message: 200,
          description: i18n.t('accountSettings:notification.userUpdateTrue'),
          duration: 3
        })
    
} else {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      updating: false,
    }
  })
  notification.warning({
    message: 500,
    description: i18n.t('accountSettings:notification.userUpdateFalse'),
    duration: 3
  })
} 
}
export function* REQUEST_PLAN_UPGRADE({ payload }) {
  const data = yield call(requestPlanUpgrade, payload)
  if (data.statusCode === 200) {
    notification.success({
      message: 200,
      description: i18n.t('accountSettings:notification.requestPlanTrue'),
      duration: 3,
    })
  } else {
    notification.warning({
      message: 500,
      description: i18n.t('accountSettings:notification.requestPlanFalse'),
      duration: 3,
    })
  }
}



export function* UPDATE_FCM_TOKEN({payload}){
  yield call(updateFCM , payload);
  // return;
}

export function* GET_ONBOARDING_STATUS({payload}){
  yield put({
    type: 'stats/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data =  yield call(getOnBoarding , payload);
  yield put({
    type: 'user/UPDATE_ONBOARDING_STATUS',
    payload: {
      ...data
    }
  })
  yield put({
    type: 'stats/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* RESET_PASSWORD({payload}){
  yield put({
    type: 'rewards/SET_STATE',
    payload: {
      updating: true
    }
  })
  const data =  yield call(resetPassword , payload);
  if (data.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        updating: false,
      }
    })
    notification.success({
      message: 200,
      description: i18n.t('accountSettings:notification.userUpdateTrue'),
      duration: 3
    })
} else {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      updating: false,
    }
  })
  notification.warning({
    message: 500,
    description: i18n.t('accountSettings:notification.userUpdateFalse'),
    duration: 3
  })
} 
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGIN_OTP, LOGIN_OTP),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.LOAD_ACCOUNT_DATA, LOADACCOUNTDATA),    
    takeEvery(actions.PAYMENT, PAYMENT),
    takeEvery(actions.CLOSE_ALERT, CLOSE_ALERT),
    takeEvery(actions.REQUEST_RESET, REQUEST_RESET),
    takeEvery(actions.RESET, RESET),
    takeEvery(actions.UPDATE_USER, UPDATE_USER),
    takeEvery(actions.UPDATE_FCM_TOKEN, UPDATE_FCM_TOKEN),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.GET_ONBOARDING_STATUS, GET_ONBOARDING_STATUS),
    takeEvery(actions.REQUEST_PLAN_UPGRADE, REQUEST_PLAN_UPGRADE),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
