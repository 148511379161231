/* eslint-disable import/prefer-default-export */
import cookie from 'js-cookie'
import axios from 'axios'
import { API_ADDRESS } from '../../services/config'

export async function getIntegrations(params) {
  try {
    const { data } = await axios.get(`${API_ADDRESS}/businesses/${params.businessId}/integration`, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (response) {
    return response
  }
}

export async function activateIntegration(params) {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/businesses/${params.businessId}/integration`, params, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (response) {
    return response
  }
}
export async function deactivateIntegration(params) {
  try {
    const { data } = await axios.patch(`${API_ADDRESS}/businesses/${params.businessId}/integration/${params.id}`, params, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (response) {
    return response
  }
}