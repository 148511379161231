import { all, takeEvery, put, call  } from 'redux-saga/effects'
import { notification } from 'antd'
import i18n from "i18next"
import { getBranches, addBranches , updateBranch , deleteBranch, syncBranches} from '../service'
import actions from './actions'
import WarningMessage from '../components/WarningMessage';

export function* SYNC_BRANCHES({ payload }) {
  yield put({
    type: 'branch/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(syncBranches, payload)

  if (data.status) {
    yield put({
      type: 'branch/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'branch/GET_BRANCHES',
      payload:{
        businessId: payload.businessId
      }
    })
  } else {
    yield put({
      type: 'branch/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 500,
      description: i18n.t('loyaltyProgram:notification.syncError'),
      duration: 3
    })
  }
}

export function* ADD_BRANCHES({ payload }) {  
  yield put({
    type: 'branch/SET_STATE',
    payload: {
      loading: true,
    }
  })
  const data =  yield call(addBranches , payload);
  if (data.status === true) {
      yield put({
        type: 'branch/SET_STATE',
        payload: {
          loading: false
        }
      })
      yield put({
        type: 'branch/GET_BRANCHES',
        payload:{
          businessId: payload.businessId
        }
      })
      notification.success({
        message: 200,
        description: i18n.t('branches:notification.addTrue'),
        duration: 3
      })
  } else {
    yield put({
      type: 'branch/SET_STATE',
      payload: {
        loading: false,
      }
    })
    notification.warning({
      message: 500,
      description: i18n.t('branches:notification.addFalse'),
      duration: 3
    })
  }
}

export function* UPDATE_BRANCH({ payload }) {  
yield put({
  type: 'branch/SET_STATE',
  payload: {
    loading: true,
    },
  })
  const data = yield call(updateBranch, payload)
  if (data.status === true) {
    yield put({
      type: 'branch/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'branch/GET_BRANCHES',
      payload: {
        businessId: payload.businessId,
      },
    })
    notification.success({
      message: 200,
      description: i18n.t('branches:notification.updateTrue'),
      duration: 3,
    })
  } else if (data.status === 403) {
    yield put({
      type: 'branch/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: i18n.t('branches:notification.watchIt'),
      // eslint-disable-next-line react/react-in-jsx-scope
      description: <WarningMessage />,
      duration: 0
    })
  } else {
    yield put({
      type: 'branch/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 500,
      description: i18n.t('branches:notification.updateFalse'),
      duration: 3,
    })
  }
}

export function* GET_BRANCHES({ payload }) {  
yield put({
  type: 'branch/SET_STATE',
  payload: {
    loading: true,
  },
})
const data =  yield call(getBranches , payload);        
if (data.status) {   
  const branches = data.data;   
  yield put({
    type: 'branch/SET_STATE',
    payload: {
      branches,
      loading: false,
    }
  })
} else {
  yield put({
    type: 'branch/SET_STATE',
    payload: {
      branches:[],
      loading: false,
    }
  })
} 
}

export function* DELETE_BRANCH({ payload }){
yield put({
  type: 'branch/SET_STATE',
  payload: {
    loading: true  
  }
})
  const response =  yield call(deleteBranch , payload);
  if (response.status) {  
    yield put({
      type: 'branch/DELETE_BRANCH_ELEM',
      payload: {
        loading: false,
        id: payload.id
      }
    })

    notification.success({
      message: 200,
      description: i18n.t('branches:notification.deleteTrue'),
      duration: 3
    })
  } else {
    notification.warning({
      message: 500,
      description: i18n.t('branches:notification.deleteFalse'),
      duration: 3
    })
  }
  
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD_BRANCHES,ADD_BRANCHES),
    takeEvery(actions.DELETE_BRANCH,DELETE_BRANCH),
    takeEvery(actions.UPDATE_BRANCH,UPDATE_BRANCH),
    takeEvery(actions.GET_BRANCHES,GET_BRANCHES),
    takeEvery(actions.SYNC_BRANCHES,SYNC_BRANCHES),
  ])
}
  