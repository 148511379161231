const actions = {
    SET_STATE: 'rewards/SET_STATE',
    RESET_STATE: 'rewards/RESET_STATE',
    GET_REWARDS: 'rewards/GET_REWARDS',
    GET_SINGLE_REWARD: 'rewards/GET_SINGLE_REWARD',
    NEW_REWARD: 'rewards/NEW_REWARD',
    ADD_REWARD_ELEM: 'rewards/ADD_REWARD_ELEM',
    UPDATE_REWARD: 'rewards/UPDATE_REWARD',
    DELETE_REWARD: 'rewards/DELETE_REWARD',
    SELECTED_REWARD: 'rewards/SELECTED_REWARD',
    }
  
    export default actions