const actions = {
    SET_STATE: 'products/SET_STATE',
    GET_PRODUCTS: 'products/GET_PRODUCTS',
    ADD_PRODUCT: 'products/ADD_PRODUCT',
    DELETE_PRODUCT: 'products/DELETE_PRODUCT',
    DELETE_PRODUCT_ELEM: 'products/DELETE_PRODUCT_ELEM',
    UPDATE_PRODUCT: 'products/UPDATE_PRODUCT',
    GET_CATEGORIES: 'products/GET_CATEGORIES',
    GET_CATEGORIES_LIST: 'products/GET_CATEGORIES_LIST',
    ADD_CATEGORY: 'products/ADD_CATEGORY',
    DELETE_CATEGORY: 'products/DELETE_CATEGORY',
    DELETE_CATEGORY_ELEM: 'products/DELETE_CATEGORY_ELEM',
    UPDATE_CATEGORY: 'products/UPDATE_CATEGORY',
    SYNC_MENU: 'products/SYNC_MENU',
    GET_MENU_STATS: 'products/GET_MENU_STATS',
}

export default actions