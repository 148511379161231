/* eslint-disable no-unused-vars */
import { all, takeEvery, put, call  } from 'redux-saga/effects'
import { notification } from 'antd'
import i18n from "i18next"
import { getLoyalty, updateLoyalty, getLevels, newLevel, updateLevel , deleteLevel, getHappyHours, newHappyHour, deleteHappyHour, updateHappyHour, getStampCards, newStampCard, deleteStampCard, updateStampCard } from '../service'
import actions from './actions'

export function* GET_LOYALTY({ payload }) {  
    yield put({
      type: 'loyalty/SET_STATE',
      payload: {
        loading: true,
      },
    })
    yield put({
      type: 'loyalty/GET_STAMP_CARDS',
    })
    const data =  yield call(getLoyalty , payload);    
    if (data.status) {   
      const loyalty = data.data;   
      yield put({
        type: 'loyalty/SET_STATE',
        payload: {
          loyalty,
          loading: false,
        }
      })
    } else {
      yield put({
        type: 'loyalty/SET_STATE',
        payload: {
          loyalty: null,
          loading: false,
        }
      })
    }
    
}

export function* UPDATE_LOYALTY({ payload }) {  
    yield put({
      type: 'loyalty/SET_STATE',
      payload: {
        updating: true
      }
    })
    const data =  yield call(updateLoyalty , payload);

    if (data.status) {
      yield put({
        type: 'loyalty/SET_LOYALTY_ATTR',
        payload: {
          ...payload,
        }
      })
      yield put({
        type: 'loyalty/SET_STATE',
        payload: {
          // ...payload,
          updating: false
        }
      })
      
      notification.success({
        message: 200,
        description: i18n.t('loyaltyProgram:notification.updateTrue'),
        duration: 3
      })
  } else {
    yield put({
      type: 'loyalty/SET_STATE',
      payload: {
        updating: false,
      }
    })
    notification.warning({
      message: 500,
      description: i18n.t('loyaltyProgram:notification.updateFalse'),
      duration: 3
    })
  } 
}

export function* GET_LEVELS({ payload }) {  
  yield put({
    type: 'loyalty/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data =  yield call(getLevels , payload);      
  if (data.status) {   
    const levels = data.data;   
    yield put({
      type: 'loyalty/SET_LOYALTY_ATTR',
      payload: {
        levels,
        loading: false,
      }
    })
  } else {
    yield put({
      type: 'loyalty/SET_LOYALTY_ATTR',
      payload: {
        levels:[] ,
        loading: false,
      }
    })
  }
}

export function* NEW_LEVEL({ payload }) {  
    yield put({
      type: 'loyalty/SET_STATE',
      payload: {
        loading: true,
      }
    })
    const data =  yield call(newLevel , payload);
    if (data.status === true) {
        yield put({
          type: 'loyalty/SET_STATE',
          payload: {
            loading: false
          }
        })
        yield put({
          type: 'loyalty/GET_LEVELS',
          payload:{
            businessId: payload.businessId
          }
        })
        notification.success({
          message: 200,
          description: i18n.t('loyaltyProgram:notification.levelAddTrue'),
          duration: 3
        })
    } 
    else{
      yield put({
        type: 'loyalty/SET_STATE',
        payload: {
          loading: false,
        }
      })
      if(data.data.key === "name"){
        notification.warning({
          message: data.status,
          description: i18n.t('loyaltyProgram:notification.levelNameFalse'),
          duration: 3
        })
      }
      else if(data.data.key === "points"){
        notification.warning({
          message: data.status,
          description: i18n.t('loyaltyProgram:notification.levelPointsFalse'),
          duration: 3
        })
      }else{
        notification.warning({
          message: 500,
          description: i18n.t('loyaltyProgram:notification.levelAddFalse'),
          duration: 3
        })
      }
    }
}

export function* DELETE_LEVEL({ payload }){
  yield put({
    type: 'loyalty/SET_STATE',
    payload: {
      loading: true  
    }
  })
    const response =  yield call(deleteLevel , payload);
    if (response.status) {  
      yield put({
        type: 'loyalty/SET_STATE',
        payload: {
          loading: false
        }
      })
      yield put({
        type: 'loyalty/GET_LOYALTY',
        payload:{
          businessId: payload.businessId
        }
      })
      notification.success({
        message: 200,
        description: i18n.t('loyaltyProgram:notification.levelDeleteTrue'),
        duration: 3
      })
    } else {
      notification.warning({
        message: 500,
        description: i18n.t('loyaltyProgram:notification.levelDeleteFalse'),
        duration: 3
      })
    }
    
}

export function* UPDATE_LEVEL({ payload }){
  yield put({
    type: 'loyalty/SET_STATE',
    payload: {
      loading: true  
    }
  })
    const response =  yield call(updateLevel , payload);
    if (response.status) {  
      yield put({
        type: 'loyalty/SET_STATE',
        payload: {
          loading: false
        }
      })
      yield put({
        type: 'loyalty/GET_LOYALTY',
        payload:{
          businessId: payload.businessId
        }
      })
      notification.success({
        message: 200,
        description: i18n.t('loyaltyProgram:notification.levelUpdateTrue'),
        duration: 3
      })
    } else {
      notification.warning({
        message: 500,
        description: i18n.t('loyaltyProgram:notification.levelUpdateFalse'),
        duration: 3
      })
    }
    
}

export function* GET_STAMP_CARDS({ payload }) {  
  yield put({
    type: 'loyalty/SET_STATE',
    payload: {
      selected: null,
      loading: true,
    },
  })
  const data =  yield call(getStampCards , payload);      
  if (data.status) {
    if(payload?.id){
      const selected = data.data;   
      yield put({
        type: 'loyalty/SET_STATE',
        payload: {
          selected,
          loading: false,
        }
      })
    } else {
      const { stampCards } = data.data;   
      yield put({
        type: 'loyalty/SET_STATE',
        payload: {
          stampCards,
          loading: false,
        }
      })
    }
    
  } else {
    yield put({
      type: 'loyalty/SET_STATE',
      payload: {
        stampCards:[] ,
        selected: null,
        loading: false,
      }
    })
  }
}

export function* NEW_STAMP_CARD({ payload }) {  
    yield put({
      type: 'loyalty/SET_STATE',
      payload: {
        loading: true,
      }
    })
    const data =  yield call(newStampCard , payload);
    if (data.status === true) {
        yield put({
          type: 'loyalty/SET_STATE',
          payload: {
            loading: false
          }
        })
        yield put({
          type: 'loyalty/GET_STAMP_CARDS',
        })
        notification.success({
          message: 200,
          description: i18n.t('loyaltyProgram:notification.stampAddTrue'),
          duration: 3
        })
    } else {
      yield put({
        type: 'loyalty/SET_STATE',
        payload: {
          loading: false,
        }
      })
      notification.warning({
        message: 500,
        description: i18n.t('loyaltyProgram:notification.stampAddFalse'),
        duration: 3
      })
    }
}

export function* DELETE_STAMP_CARD({ payload }){
  yield put({
    type: 'loyalty/SET_STATE',
    payload: {
      loading: true  
    }
  })
    const response =  yield call(deleteStampCard , payload);
    if (response.status) {  
      yield put({
        type: 'loyalty/SET_STATE',
        payload: {
          loading: false
        }
      })
      yield put({
        type: 'loyalty/REMOVE_STAMP_CARD',
        payload:{
          id: payload.id
        }
      })
      notification.success({
        message: 200,
        description: i18n.t('loyaltyProgram:notification.stampDeleteTrue'),
        duration: 3
      })
    } else {
      notification.warning({
        message: 500,
        description: i18n.t('loyaltyProgram:notification.stampDeleteFalse'),
        duration: 3
      })
    }
    
}

export function* UPDATE_STAMP_CARD({ payload }){
  yield put({
    type: 'loyalty/SET_STATE',
    payload: {
      loading: true  
    }
  })
    const response =  yield call(updateStampCard , payload);
    if (response.status) {  
      yield put({
        type: 'loyalty/SET_STATE',
        payload: {
          loading: false
        }
      })
      notification.success({
        message: 200,
        description: i18n.t('loyaltyProgram:notification.stampUpdateTrue'),
        duration: 3
      })
      yield put({
        type: 'loyalty/GET_STAMP_CARDS',
      })
    } else {
      notification.warning({
        message: 500,
        description: i18n.t('loyaltyProgram:notification.stampUpdateFalse'),
        duration: 3
      })
    }
    
}

export function* GET_HAPPY_HOURS({ payload }) {  
  yield put({
    type: 'loyalty/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data =  yield call(getHappyHours , payload);    
  if (data.status) {   
    const happyHours = data.data;   
    yield put({
      type: 'loyalty/SET_LOYALTY_ATTR',
      payload: {
        happyHours,
        loading: false,
      }
    })
  } else {
    yield put({
      type: 'loyalty/SET_STATE',
      payload: {
        happyHours:[] ,
        loading: false,
      }
    })
  }
  
}

export function* NEW_HAPPY_HOUR({ payload }) {  
    yield put({
      type: 'loyalty/SET_STATE',
      payload: {
        loading: true,
      }
    })
    const data =  yield call(newHappyHour , payload);
    if (data.status === true) {
        yield put({
          type: 'loyalty/SET_STATE',
          payload: {
            loading: false
          }
        })
        yield put({
          type: 'loyalty/GET_HAPPY_HOURS',
          payload:{
            businessId: payload.businessId
          }
        })
        notification.success({
          message: 200,
          description: i18n.t('loyaltyProgram:notification.hourAddTrue'),
          duration: 3
        })
    } else {
      yield put({
        type: 'loyalty/SET_STATE',
        payload: {
          loading: false,
        }
      })
      notification.warning({
        message: 500,
        description: i18n.t('loyaltyProgram:notification.hourAddFalse'),
        duration: 3
      })
    }
}

export function* DELETE_HAPPY_HOUR({ payload }){
  yield put({
    type: 'loyalty/SET_STATE',
    payload: {
      loading: true  
    }
  })
    const response =  yield call(deleteHappyHour , payload);
    if (response.status) {  
      yield put({
        type: 'loyalty/SET_STATE',
        payload: {
          loading: false
        }
      })
      yield put({
        type: 'loyalty/GET_HAPPY_HOURS',
        payload:{
          businessId: payload.businessId
        }
      })
      notification.success({
        message: 200,
        description: i18n.t('loyaltyProgram:notification.hourDeleteTrue'),
        duration: 3
      })
    } else {
      notification.warning({
        message: 500,
        description: i18n.t('loyaltyProgram:notification.hourDeleteFalse'),
        duration: 3
      })
    }
    
}

export function* UPDATE_HAPPY_HOUR({ payload }){
  yield put({
    type: 'loyalty/SET_STATE',
    payload: {
      loading: true  
    }
  })
    const response =  yield call(updateHappyHour , payload);
    if (response.status) {  
      yield put({
        type: 'loyalty/SET_STATE',
        payload: {
          loading: false
        }
      })
      yield put({
        type: 'loyalty/GET_HAPPY_HOURS',
        payload:{
          businessId: payload.businessId
        }
      })
      notification.success({
        message: 200,
        description: i18n.t('loyaltyProgram:notification.hourUpdateTrue'),
        duration: 3
      })
    } else {
      notification.warning({
        message: 500,
        description: i18n.t('loyaltyProgram:notification.hourUpdateFalse'),
        duration: 3
      })
    }
    
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_LOYALTY, GET_LOYALTY),  
        takeEvery(actions.UPDATE_LOYALTY, UPDATE_LOYALTY),  
        takeEvery(actions.GET_LEVELS, GET_LEVELS), 
        takeEvery(actions.DELETE_LEVEL, DELETE_LEVEL), 
        takeEvery(actions.UPDATE_LEVEL, UPDATE_LEVEL), 
        takeEvery(actions.NEW_LEVEL, NEW_LEVEL),
        takeEvery(actions.GET_HAPPY_HOURS, GET_HAPPY_HOURS), 
        takeEvery(actions.DELETE_HAPPY_HOUR, DELETE_HAPPY_HOUR), 
        takeEvery(actions.UPDATE_HAPPY_HOUR, UPDATE_HAPPY_HOUR), 
        takeEvery(actions.NEW_HAPPY_HOUR, NEW_HAPPY_HOUR),
        takeEvery(actions.GET_STAMP_CARDS, GET_STAMP_CARDS),
        takeEvery(actions.NEW_STAMP_CARD, NEW_STAMP_CARD),
        takeEvery(actions.UPDATE_STAMP_CARD, UPDATE_STAMP_CARD),
        takeEvery(actions.DELETE_STAMP_CARD, DELETE_STAMP_CARD),
    ])
}
  