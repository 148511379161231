/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  integrations: [],
  foodics: false,
  loading: false,
}

export default function integrationsReducer(state = initialState, action) {      
    switch (action.type) {
      case actions.SET_STATE:
        return { ...state, ...action.payload }
      case actions.SET_INTEGRATIONS:
        return { ...state, 
          foodics: state.integrations.find((integration) => integration.integrationName === 'FOODICS')?.active || false
        }
      default:
        return state
    }
  }