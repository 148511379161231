/* eslint-disable no-case-declarations */
import actions from './actions'

const initialState = {
    rewards: [],
    loading: false,
    selected: null,
    creating:false
}

export default function rewardsReducer(state = initialState, action) {  
    switch (action.type) {
      case actions.SET_STATE:
        return { ...state, ...action.payload }
      case actions.RESET_STATE:
        return { ...initialState }
      case actions.SELECTED_REWARD:
        let {selected} = state;
        selected = { ...selected , ...action.payload.selected }
        return {
          ...state,
          selected: {...selected},
          }
      case actions.ADD_REWARD_ELEM:
        return {
          ...state,
          creating: action.payload.creating,
          rewards: [{...action.payload.reward}, ...state.rewards],
        }
      case actions.DELETE_REWARD_ELEM:
        return {
          ...state,
          rewards: [{...state.selected}, ...state.rewards],
        }
      default:
        return state
    }
  }