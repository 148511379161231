/* eslint-disable no-case-declarations */
/* eslint-disable no-return-assign */
import actions from './actions'

const initialState = {
    loyalty: null,  
    stampCards: [],
    selected: null,
    loading: false,
    creating:false,
    updating: false
}

export default function loyaltyReducer(state = initialState, action) {  
    switch (action.type) {
      case actions.SET_STATE:
        return { ...state, ...action.payload }
      case actions.RESET_STATE:
        return { ...initialState }
      case actions.SET_LOYALTY_ATTR:
          const {loyalty} = state;
          Object.keys(action.payload).map(k =>( 
            loyalty[k] = action.payload[k]
          ))
          return {
            ...state,
            loyalty,
            loading: false
          }
      case actions.REMOVE_STAMP_CARD:
        const { stampCards } = state;
        // eslint-disable-next-line no-underscore-dangle
        const newStampCards = stampCards.filter(card => card._id !== action.payload.id);
        return {
          ...state,
          stampCards: newStampCards,
          loading: false
        }
      case actions.SET_SELECTED_STAMP_CARD:
        const { selected } = state;
        return {
          ...state,
          selected,
          loading: false
        }
      default:
        return state
    }
}