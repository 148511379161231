import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // VB:REPLACE-START:ROUTER-CONFIG
  {
    path: '/start',
    Component: lazy(() => import('pages/onboarding')),
    exact: true,
  },
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },
  {
    path: '/members',
    Component: lazy(() => import('pages/customers')),
    exact: true,
  },
  {
    path: '/members/:id',
    Component: lazy(() => import('pages/single-customer')),
    exact: true,
  },
  {
    path: '/branches',
    Component: lazy(() => import('pages/branches')),
    exact: true,
  },
  {
    path: '/transactions',
    Component: lazy(() => import('pages/transactions')),
    exact: true,
  },
  {
    path: '/feedback',
    Component: lazy(() => import('pages/feedback')),
    exact: true,
  },
  {
    path: '/campaigns',
    Component: lazy(() => import('pages/campaigns')),
    exact: true,
  },
  {
    path: '/campaigns/create',
    Component: lazy(() => import('pages/create-campaign')),
    exact: true,
  },
  {
    path: '/campaigns/:id',
    Component: lazy(() => import('pages/single-campaign')),
    exact: true,
  },
  {
    path: '/policies-terms/',
    Component: lazy(() => import('pages/policies-terms')),
    exact: true,
  },
  {
    path: '/ar/الشروط-والخصوصية/',
    Component: lazy(() => import('pages/policies-terms-ar')),
    exact: true,
  },
  {
    path: '/foodics-new',
    Component: lazy(() => import('pages/foodics-new')),
    exact: true,
  },
  {
    path: '/foodics-success',
    Component: lazy(() => import('pages/foodics-success')),
    exact: true,
  },
  {
    path: '/settings/account',
    Component: lazy(() => import('pages/account-settings')),
    exact: true,
  },
  {
    path: '/settings/profile',
    Component: lazy(() => import('pages/profile-settings')),
    exact: true,
  },
  {
    path: '/settings/integration',
    Component: lazy(() => import('pages/integration')),
    exact: true,
  },
  {
    path: '/settings/products',
    Component: lazy(() => import('pages/products')),
    exact: true,
  },
  {
    path: '/settings/plan',
    Component: lazy(() => import('pages/plan')),
    exact: true,
  },
  // {
  //   path: '/multi-visits',
  //   Component: lazy(() => import('pages/multi-visits')),
  //   exact: true,
  // },
  {
    path: '/wallet/settings',
    Component: lazy(() => import('pages/settings-wallet')),
    exact: true,
  },
  {
    path: '/wallet/create',
    Component: lazy(() => import('pages/create-wallet')),
    exact: true,
  },
  {
    path: '/wallet',
    Component: lazy(() => import('pages/wallet')),
    exact: true,
  },
  {
    path: '/rewards',
    Component: lazy(() => import('pages/rewards')),
    exact: true,
  },
  {
    path: '/rewards/create',
    Component: lazy(() => import('pages/create-rewards')),
    exact: true,
  },
  {
    path: '/program/view/:program?/:subProgram?',
    Component: lazy(() => import('pages/view-loyalty-program')),
    exact: true,
  },
  {
    path: '/program/setup',
    Component: lazy(() => import('pages/setup-points-program')),
    exact: true,
  },
  {
    path: '/program/setup/stamp-card/:program?/:id?',
    Component: lazy(() => import('pages/setup-stamps')),
    exact: true,
  },
  {
    path: '/program',
    Component: lazy(() => import('pages/loyalty-program')),
    exact: true,
  },
  // {
  //   path: '/rewards',
  //   Component: lazy(() => import('pages/rewards')),
  //   exact: true,
  // },
  // {
  //   path: '/pending-transactions',
  //   Component: lazy(() => import('pages/transactions/old/transactions/components/ClaimedTransactions')),
  //   exact: true,
  // },
  {
    path: '/auth/verify-customer/:id',
    Component: lazy(() => import('pages/verify-customer')),
    exact: true,
  },
  {
    path: '/auth/verify/:id',
    Component: lazy(() => import('pages/verify')),
    exact: true,
  },

  // VB:REPLACE-END:ROUTER-CONFIG
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password/:id',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/forgot-password-request',
    Component: lazy(() => import('pages/auth/forgot-password-request')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT */}
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
