import cookie from 'js-cookie'
import axios from 'axios'
import { API_ADDRESS } from '../../services/config'


export async function getCustomers(params){
  try{
    const {
      data
    } = await axios.post(`${API_ADDRESS}/businesses/${params.businessId}/customers/${ (params && params.customerId) ? params.customerId : '' }`, params,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}

export async function banCustomer(params){  
  try{
    const { data } = await axios.post(`${API_ADDRESS}/businesses/${params.businessId}/customers/${params.id}/ban?ban=${params.banned}`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });

    return data;
  }catch({response}){    
    return response;
  }
}

export async function syncCustomers(params) {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/foodics/sync-customers`, params, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (response) {
    return response
  }
}
