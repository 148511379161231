import cookie from 'js-cookie'
import axios from 'axios'
import {API_ADDRESS  } from '../../services/config'


export async function getLoyalty(params){
  try{
    const {
      data
    } = await axios.get(`${API_ADDRESS}/businesses/${params.businessId}/loyalties`,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}

export async function updateLoyalty(params){
  try{
    const {
      data
    } = await axios.patch(`${API_ADDRESS}/businesses/${params.businessId}/loyalties`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}

export async function getLevels(params){
  try{
    const {
      data
    } = await axios.get(`${API_ADDRESS}/businesses/${params.businessId}/levels/${ (params && params.id) ? params.id : '' }`,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}

export async function newLevel(params){ 
  try{
    const { data } = await axios.post(`${API_ADDRESS}/businesses/${params.businessId}/levels`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch({response}){    
    return response;
  }
}

export async function updateLevel(params){  
  try{
    const { data } = await axios.patch(`${API_ADDRESS}/businesses/${params.businessId}/levels/${params.id}`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });

    return data;
  }catch({response}){    
    return response;
  }
}

export async function deleteLevel(params){  
  try{
    const { data } = await axios.delete(`${API_ADDRESS}/businesses/${params.businessId}/levels/${params.id}`,
    { headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  }});
    return data;
  }catch({response}){
    return response;
  }
}

export async function getStampCards(params){
  try{
    const {
      data
    } = await axios.get(`${API_ADDRESS}/stamp-cards/${ (params && params.id) ? params.id : '' }`,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}

export async function newStampCard(params){ 
  try{
    const { data } = await axios.post(`${API_ADDRESS}/stamp-cards`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch({response}){    
    return response;
  }
}

export async function updateStampCard(params){  
  try{
    const { data } = await axios.patch(`${API_ADDRESS}/stamp-cards/${params.id}`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });

    return data;
  }catch({response}){    
    return response;
  }
}

export async function deleteStampCard(params){  
  try{
    const { data } = await axios.delete(`${API_ADDRESS}/stamp-cards/${params.id}`,
    { headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  }});
    return data;
  }catch({response}){
    return response;
  }
}

export async function getHappyHours(params){
  try{
    const {
      data
    } = await axios.get(`${API_ADDRESS}/businesses/${params.businessId}/happy-hours/${ (params && params.id) ? params.id : '' }`,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}

export async function newHappyHour(params){ 
  try{
    const { data } = await axios.post(`${API_ADDRESS}/businesses/${params.businessId}/happy-hours`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch({response}){    
    return response;
  }
}

export async function updateHappyHour(params){  
  try{
    const { data } = await axios.patch(`${API_ADDRESS}/businesses/${params.businessId}/happy-hours/${params.id}`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });

    return data;
  }catch({response}){    
    return response;
  }
}

export async function deleteHappyHour(params){  
  try{
    const { data } = await axios.delete(`${API_ADDRESS}/businesses/${params.businessId}/happy-hours/${params.id}`,
    { headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  }});
    return data;
  }catch({response}){
    return response;
  }
}