import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import rewards from '../pages/rewards/redux/reducers'
import customers from '../pages/customers/redux/reducers'
import customer from '../pages/single-customer/redux/reducers'
import transactions from '../pages/transactions/redux/reducers'
import loyalty from '../pages/loyalty-program/redux/reducers'
import business from '../pages/settings/redux/reducers'
import stats from '../pages/dashboard/redux/reducers'
import branch from '../pages/branches/redux/reducers'
import feedbacks from '../pages/feedback/redux/reducers'
import wallets from '../pages/wallet/redux/reducers'
import products from '../pages/products/redux/reducers'
import integrations from '../pages/integration/redux/reducers'
import campaigns from '../pages/campaigns/redux/reducers'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    rewards,
    customers,
    customer,
    transactions,
    loyalty,
    business,
    stats,
    branch,
    feedbacks,
    wallets,
    products,
    integrations,
    campaigns,
  })
