import i18n from '../../i18n'

export default async function getMenuData() {
  return [
    // VB:REPLACE-START:MENU-CONFIG
    {
      title: i18n.t('menu:dashboard'),
      key: 'dashboard',
      url: '/dashboard',
      icon: ['far', 'home-alt'],
    },
    {
      title: i18n.t('menu:customers'),
      key: 'members',
      url: '/members',
      icon: ['far', 'users-crown'],
    },
    {
      title: i18n.t('menu:transactions'),
      key: 'transactions',
      url: '/transactions',
      icon: ['far', 'shopping-cart'],
      // count: 'totalUnclaimed'
    },
    {
      title: i18n.t('menu:feedback'),
      key: 'feedback',
      url: '/feedback',
      icon: ['far', 'star-exclamation'],
    },
    {
      title: i18n.t('menu:campaigns'),
      key: 'campaigns',
      url: '/campaigns',
      icon: ['far', 'megaphone'],
    },
    {
      title: i18n.t('menu:loyaltyProgram'),
      key: 'loyaltyProgram',
      url: '/program',
      icon: ['far', 'hands-heart'],
    },
    {
      title: i18n.t('menu:settings'),
      key: 'settings',
      url: '/settings',
      icon: ['far', 'cogs'],
      children: [
        {
          title: i18n.t('menu:profileSettings'),
          key: 'oi2iv',
          url: '/settings/profile',
        },
        {
          title: i18n.t('menu:accountSettings'),
          key: 'amhwha',
          url: '/settings/account',
        },
        {
          title: i18n.t('menu:planLimits'),
          key: '0emfdw',
          url: '/settings/plan',
        },
        {
          title: i18n.t('menu:products'),
          key: '0emfer',
          url: '/settings/products',
        },
        {
          title: i18n.t('menu:spots'),
          key: 'branches',
          url: '/branches',
        },
        {
          title: i18n.t('menu:marketplace'),
          key: '0emfef',
          url: '/settings/integration',
        },
      ],
    },
    // VB:REPLACE-END:MENU-CONFIG
  ]
}
