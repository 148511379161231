/* eslint-disable no-case-declarations */
import actions from './actions'

const initialState = {
  wallets: [],
  loading: false,
  creating: false,
  selected: null
}

export default function walletsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.RESET_STATE:
      return { ...initialState }
    default:
      return state
  }
}
