const actions = {
    SET_STATE: 'branch/SET_STATE',
    RESET_STATE: 'branch/RESET_STATE',
    ADD_BRANCHES: 'branch/ADD_BRANCHES',
    DELETE_BRANCH: 'branch/DELETE_BRANCH',
    DELETE_BRANCH_ELEM: 'branch/DELETE_BRANCH_ELEM',
    UPDATE_BRANCH: 'branch/UPDATE_BRANCH',
    SET_BRANCHES: 'branch/SET_BRANCHES',
    GET_BRANCHES: 'branch/GET_BRANCHES',
    SYNC_BRANCHES: 'branch/SYNC_BRANCHES',
}
  
export default actions