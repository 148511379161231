/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
    products: [],
    categories: [],
    categoriesList: [],
    loading: false,
    pagination: {
      total: null,
      current: 1,
      pageSize: 10
    },
    stats: {
      totalProducts: 0,
      totalCategories: 0,
    }
}

export default function productsReducer(state = initialState, action) {      
    switch (action.type) {
      case actions.SET_STATE:
        return { ...state, ...action.payload }
      case actions.RESET_STATE:
        return { ...initialState }
      case actions.DELETE_PRODUCT_ELEM:
        return {
          ...state,
          loading: action.payload.loading,
          products: state.products.filter(elm => elm._id !== action.payload.id),
        }
      case actions.DELETE_CATEGORY_ELEM:
        return {
          ...state,
          loading: action.payload.loading,
          categories: state.categories.filter(elm => elm._id !== action.payload.id),
        }
      default:
        return state
    }
  }