import React from 'react'
import { Menu, Dropdown } from 'antd'
import { connect } from 'react-redux'
import classNames from 'classnames'
import styles from './style.module.scss'

import i18n from '../../../i18n'

import FlagEn from './flags/en.svg'
// import FlagRu from './flags/ru.svg'
// import FlagFr from './flags/fr.svg'
// import FlagZh from './flags/zh.svg'
import FlagAr from './flags/ar.svg'

const mapStateToProps = ({ settings }) => ({
  locale: settings.locale,
  direction: settings.direction,
})

const LanguageSwitcher = ({ dispatch, locale, direction }) => {
  const mapFlags = {
    en: FlagEn,
    // ru: FlagRu,
    // fr: FlagFr,
    // zh: FlagZh,
    ar: FlagAr,
  }

  const changeLanguage = ({ key }) => {
    i18n.changeLanguage(key.split('-')[0])
    dispatch({
      type: 'menu/GET_DATA',
    })
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    })
    if (key === 'ar-EG') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'direction',
          value: 'rtl',
        },
      })
    } else if (direction !== 'ltr') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'direction',
          value: 'ltr',
        },
      })
    }
  }

  const language = locale.substr(0, 2)

  const menu = (
    <Menu selectedKeys={[locale]} onClick={changeLanguage}>
      <Menu.Item key="ar-EG">
        <span
          className={classNames(styles.menuIcon, { [styles.menuIcon__rtl]: direction === 'rtl' })}
        >
          <img src={mapFlags.ar} alt="العربية" />
        </span>
        العربية
      </Menu.Item>
      <Menu.Item key="en-US">
        <span
          className={classNames(styles.menuIcon, { [styles.menuIcon__rtl]: direction === 'rtl' })}
        >
          <img src={mapFlags.en} alt="English" />
        </span>
        English
      </Menu.Item>
      {/* <Menu.Item key="fr-FR">
        <span
          className={classNames(styles.menuIcon, { [styles.menuIcon__rtl]: direction === 'rtl' })}
        >
          <img src={mapFlags.fr} alt="French" />
        </span>
        French
      </Menu.Item>
      <Menu.Item key="ru-RU">
        <span
          className={classNames(styles.menuIcon, { [styles.menuIcon__rtl]: direction === 'rtl' })}
        >
          <img src={mapFlags.ru} alt="Русский" />
        </span>
        Русский
      </Menu.Item>
      <Menu.Item key="zh-CN">
        <span
          className={classNames(styles.menuIcon, { [styles.menuIcon__rtl]: direction === 'rtl' })}
        >
          <img src={mapFlags.zh} alt="简体中文" />
        </span>
        简体中文
      </Menu.Item> */}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <div className={classNames(styles.dropdown, { [styles.dropdown__rtl]: direction === 'rtl' })}>
        <div className={classNames(styles.flag, { [styles.flag__rtl]: direction === 'rtl' })}>
          <img src={mapFlags[language]} alt={language} />
          <span>{language === 'ar' ? 'ع' : language}</span>
        </div>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(LanguageSwitcher)
