/* eslint-disable import/prefer-default-export */
import cookie from 'js-cookie'
import axios from 'axios'
import { API_ADDRESS } from '../../services/config'

export async function getFeedbacks(params) {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/feedback`, params, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (response) {
    return response
  }
}
