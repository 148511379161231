import cookie from 'js-cookie'
import axios from 'axios'
import { API_ADDRESS  } from '../../services/config'


export async function getWallets(params){
  try{
    const {
      data
    } = await axios.get(`${API_ADDRESS}/wallet/${ (params && params.id) ? params.id : '' }`,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}

export async function addWallet(params){ 
  try{
    const { data } = await axios.post(`${API_ADDRESS}/wallet/`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch({response}){    
    return response;
  }
}

export async function updateWallet(params){  
  try{
    const { data } = await axios.patch(`${API_ADDRESS}/wallet/${params.id}`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });

    return data;
  }catch({response}){    
    return response;
  }
}

export async function deleteWallet(params){  
  try{
    const { data } = await axios.delete(`${API_ADDRESS}/wallet/${params.id}`,
    { headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  }});
    return data;
  }catch({response}){
    return response;
  }
}

export async function sendWalletMessage(params){ 
  try{
    const { data } = await axios.post(`${API_ADDRESS}/wallet/${params.id}/send-message`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch({response}){    
    return response;
  }
}