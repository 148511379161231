/* eslint-disable import/prefer-default-export */
import cookie from 'js-cookie'
import axios from 'axios'
import { API_ADDRESS } from '../../services/config'

export async function getProducts(params) {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/products/get`, params, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (response) {
    return response
  }
}

export async function getMenuStats(params) {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/categories/menu-stats`, params, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (response) {
    return response
  }
}
export async function syncMenu(params) {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/foodics/sync-menu`, params, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (response) {
    return response
  }
}

export async function addProduct(params) {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/products`, params, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (response) {
    return response
  }
}

export async function updateProduct(params) {
  try {
    const { data } = await axios.patch(`${API_ADDRESS}/products/${params.id}`, params, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })

    return data
  } catch ({ response }) {
    return response
  }
}

export async function deleteProduct(params) {
  try {
    const { data } = await axios.delete(`${API_ADDRESS}/products/${params.id}`, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch ({ response }) {
    return response
  }
}

export async function getCategories(params) {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/categories/get${params && params.list ? '?list=true' : ''}`, params, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (response) {
    return response
  }
}

export async function addCategory(params) {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/categories`, params, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (response) {
    return response
  }
}

export async function updateCategory(params) {
  try {
    const { data } = await axios.patch(`${API_ADDRESS}/categories/${params.id}`, params, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })

    return data
  } catch ({ response }) {
    return response
  }
}

export async function deleteCategory(params) {
  try {
    const { data } = await axios.delete(`${API_ADDRESS}/categories/${params.id}`, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch ({ response }) {
    return response
  }
}
