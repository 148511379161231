import { all, takeEvery, put, call  } from 'redux-saga/effects'
// import { notification } from 'antd'
import { getChartsData, getStats } from '../service'
import actions from './actions'


export function* GET_STATS({ payload }) {  
    yield put({
      type: 'stats/SET_STATE',
      payload: {
        loading: true,
        chartsLoading: true
      },
    })
    const data =  yield call(getStats , payload);    
    if (data.statusCode === 200) {   
      const {stats} = data.data;   
      yield put({
        type: 'stats/SET_STATE',
        payload: {
          stats,
          loading: false,
        }
      })
      
    } else {
      yield put({
        type: 'stats/SET_STATE',
        payload: {
          stats: {},
          loading: false,
        }
      })
    }
    
}

export function* GET_CHART_DATA({ payload }) {  
  const data =  yield call(getChartsData , payload);    
  
  if (data.status) {   
    if(data.data.lastMonth){
      const stats = data.data.lastMonth;   
      yield put({
        type: 'stats/SET_STATE',
        payload: {
          revenue: stats.revenue,
          customers: stats.customers,
          points: stats.points,
          earnedRewards: stats.earnedRewards,
          redeemedRewards: stats.redeemedRewards,
          giftCards: stats.giftCards,
          burnedPoints: stats.burnedPoints,
          chartsLoading: false,
        }
      })
    } else {
      yield put({
        type: 'stats/SET_STATE',
        payload: {
          revenue: [],
          customers: [],
          points: [],
          earnedRewards: [],
          redeemedRewards: [],
          giftCards: [],
          burnedPoints: [],
          chartsLoading: false,
        }
      })
    }   
    
  } else {
    yield put({
      type: 'stats/SET_STATE',
      payload: {
        chartsLoading: false,
      }
    })
  }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_STATS, GET_STATS),  
        takeEvery(actions.GET_CHART_DATA, GET_CHART_DATA),
    ])
}
  