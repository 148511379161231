import cookie from 'js-cookie'
import axios from 'axios'
import { API_ADDRESS  } from '../../services/config'

/**
 * 1. get Total stats
 * 2. get Charts data
 */ 

export async function getStats(params){
  try{
    const {
      data
    } = await axios.get(`${API_ADDRESS}/businesses/${params.id}/statistics`,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}


export async function getChartsData(params){
  try{
    const {
      data
    } = await axios.get(`${API_ADDRESS}/businesses/${params.id}/analytics`,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}


