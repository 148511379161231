const actions = {
    SET_STATE: 'wallets/SET_STATE',
    RESET_STATE: 'wallets/RESET_STATE',
    GET_WALLETS: 'wallets/GET_WALLETS',
    ADD_WALLET: 'wallets/ADD_WALLET',
    UPDATE_WALLET: 'wallets/UPDATE_WALLET',
    DELETE_WALLET: 'wallets/DELETE_WALLET',
    SEND_WALLET_MESSAGE: 'wallets/SEND_WALLET_MESSAGE',
}

export default actions