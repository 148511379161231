import cookie from 'js-cookie'
import axios from 'axios'
import {API_ADDRESS  } from '../../services/config'

export async function getBusiness(params){
  try{
    const {
      data
    } = await axios.get(`${API_ADDRESS}/businesses/${params.businessId}` ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){ 
    return response;
  } 
}
export async function updateBusiness(params){  
  try{
    const { data } = await axios.patch(`${API_ADDRESS}/businesses/${params.businessId}`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });

    return data;
  }catch({response}){    
    return response;
  }
}

// team managment 
export async function getTeam(params){
  try{
    const {
      data
    } = await axios.get(`${API_ADDRESS}/businesses/${params.businessId}/team${ params?.cashier ? '?cashier=true' : '' }`,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}

export async function addMember(params){ 
  try{
    const { data } = await axios.post(`${API_ADDRESS}/businesses/${params.businessId}/team`, {...params.member} ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch({response}){    
    return response;
  }
}

export async function deleteMember(params){  
  try{
    const { data } = await axios.delete(`${API_ADDRESS}/businesses/${params.businessId}/team/${params.id}`,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });

    return data;
  }catch({response}){    
    return response;
  }
}

export async function updateMember(params){  
  try{
    const { data } = await axios.patch(`${API_ADDRESS}/businesses/${params.businessId}/team/${params.id}`, params,
    { headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  }});
    return data;
  }catch({response}){
    return response;
  }
}

export async function updateMemberPassword(params){  
  try{
    const { data } = await axios.patch(`${API_ADDRESS}/businesses/${params.businessId}/team/${params.id}/password`, params,
    { headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  }});
    return data;
  }catch({response}){
    return response;
  }
}