import actions from './actions'

const initialState = {
  campaigns: [],
  loading: false,
  creating: false,
  selected: null,
  pagination: {
    total: null,
    current: 1,
    pageSize: 10,
  },
  stats: {
    nCampaigns: 0,
    nCampaignsSent: 0,
    rewards: 0,
    redeemedRewards: 0,
    redemptionRate: 0,
  },
}

export default function campaignsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.RESET_STATE:
      return { ...initialState }
    default:
      return state
  }
}
