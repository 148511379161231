import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { Dropdown, Input, Tooltip, message } from 'antd'
import PerfectScrollbar from 'react-perfect-scrollbar'
import store from 'store'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from './style.module.scss'

const mapStateToProps = ({ menu, settings }) => ({
  menuData: menu.menuData,
  direction: settings.direction,
})

const FavPages = ({ menuData = [], intl: { formatMessage }, direction }) => {
  const [searchText, setSearchText] = useState('')
  const [favs, setFavs] = useState(store.get('app.topbar.favs') || [])
  const [pagesList, setPagesList] = useState([])

  useEffect(() => {
    const getPagesList = () => {
      const menuDataProcessed = JSON.parse(JSON.stringify(menuData))
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          if (item.category) {
            return flattenedItems
          }
          if (item.key === 'nestedItem1' || item.disabled) {
            // skip unwanted items
            return flattenedItems
          }
          if (Array.isArray(item[key])) {
            const itemsProcessed = item[key].map((child) => {
              child.icon = item.icon
              return child
            })
            return flattenedItems.concat(flattenItems(itemsProcessed, key))
          }
          flattenedItems.push(item)
          return flattenedItems
        }, [])
      return flattenItems(menuDataProcessed, 'children')
    }
    setPagesList(getPagesList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData])

  const changeSearchText = (e) => {
    setSearchText(e.target.value)
  }

  const setFav = (e, item) => {
    e.preventDefault()
    e.stopPropagation()
    const isActive = favs.some((child) => child.url === item.url)
    if (isActive) {
      const filtered = favs.filter((child) => child.url !== item.url)
      store.set('app.topbar.favs', filtered)
      setFavs(filtered)
      return
    }
    if (favs.length >= 3) {
      message.info('Only three pages can be added to your bookmarks.')
      return
    }
    const items = [...favs]
    items.push(item)
    store.set('app.topbar.favs', items)
    setFavs(items)
  }

  const generatePageList = () => {
    const searchTextProcessed = searchText ? searchText.toUpperCase() : ''
    return pagesList.map((item) => {
      const isActive = favs.some((child) => child.url === item.url)
      if (!item.title.toUpperCase().includes(searchTextProcessed) && searchTextProcessed) {
        return null
      }
      return (
        <Link
          to={item.url}
          className={classNames(style.link, { [style.link__rtl]: direction === 'rtl' })}
          key={item.key}
        >
          <div
            className={classNames(style.setIcon, {
              [style.setIconActive]: isActive,
              [style.setIcon__rtl]: direction === 'rtl',
            })}
            onClick={(e) => setFav(e, item)}
            role="button"
            tabIndex="0"
            onFocus={(e) => {
              e.preventDefault()
            }}
            onKeyPress={(e) => setFav(e, item)}
          >
            <FontAwesomeIcon icon={['far', 'star']} />
          </div>
          <span>
            <i className={`${direction === 'rtl' ? 'ml-2' : 'mr-2'}  fe ${item.icon}`} />
            {item.title}
          </span>
        </Link>
      )
    })
  }

  const menu = (
    <React.Fragment>
      <div className="card vb__utils__shadow width-300">
        <div className="card-body p-1 ">
          <div className="p-2">
            <Input
              placeholder={formatMessage({ id: 'topBar.findPages' })}
              value={searchText}
              onChange={changeSearchText}
              allowClear
            />
          </div>
          <div className="height-200">
            <PerfectScrollbar options={{ suppressScrollX: true, useBothWheelAxes: false }}>
              <div className="px-2 pb-2">{generatePageList(searchText)}</div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
  return (
    <div className={style.container}>
      {favs.map((item) => {
        return (
          <Tooltip key={item.key} placement="bottom" title={item.title}>
            <Link to={item.url} className={`${style.item} ${direction === 'rtl' ? 'ml-3' : 'mr-3'}`}>
              {/* <i className={`${style.icon} fe ${item.icon}`} /> */}
              <FontAwesomeIcon className={`${style.icon}`} icon={item.icon} />
            </Link>
          </Tooltip>
        )
      })}
      <Tooltip placement="bottom" title="Bookmarks">
        <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
          <span className={`${style.item} t-7`}>
            <FontAwesomeIcon className={`${style.icon}`} icon={['far', 'star']} />
          </span>
        </Dropdown>
      </Tooltip>
    </div>
  )
}

export default injectIntl(connect(mapStateToProps)(FavPages))
