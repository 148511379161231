import cookie from 'js-cookie'
import axios from 'axios'
import {API_ADDRESS  } from '../../services/config'

export async function getTransactions(params){
  try{
    const {
      data
    // } = await axios.post(`${API_ADDRESS}/transactions/${params.id}`, params,{
    } = await axios.post(`${API_ADDRESS}/transactions`, params,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}


export async function getUnclaimedTransactions(params){
  try{
    const {
      data
    } = await axios.post(`${API_ADDRESS}/transactions/${params.businessId}/unclaimed`, params,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}


export async function approveTransaction(params){
  try{
    const {
      data
    } = await axios.post(`${API_ADDRESS}/transactions/${params.businessId}/approve/${params.transactionId}`, params,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}