import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  activateIntegration,
  deactivateIntegration,
  getIntegrations,
} from '../service'
import actions from './actions'

export function* GET_INTEGRATIONS({ payload }) {
  yield put({
    type: 'integration/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(getIntegrations, payload)

  if (data.status) {
    yield put({
      type: 'integration/SET_STATE',
      payload: {
        integrations: data.data,
        loading: false,
      },
    })
    yield put({
      type: 'integration/SET_INTEGRATIONS',
    })
  } else {
    yield put({
      type: 'integration/SET_STATE',
      payload: {
        integrations: [],
        loading: false,
      },
    })
  }
}

export function* ACTIVATE_INTEGRATION({ payload }) {
  yield put({
    type: 'integration/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(activateIntegration, payload)
  if (data.status) {
    yield put({
      type: 'integration/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'integration/GET_INTEGRATIONS',
      payload
    })
    const { history } = payload;
    history.push('/foodics-new');
  } else {
    yield put({
      type: 'integration/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* DEACTIVATE_INTEGRATION({ payload }) {
  yield put({
    type: 'integration/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(deactivateIntegration, payload)
  if (data.status) {
    yield put({
      type: 'integration/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'integration/GET_INTEGRATIONS',
      payload
    })
  } else {
    yield put({
      type: 'integration/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_INTEGRATIONS, GET_INTEGRATIONS),
    takeEvery(actions.ACTIVATE_INTEGRATION, ACTIVATE_INTEGRATION),
    takeEvery(actions.DEACTIVATE_INTEGRATION, DEACTIVATE_INTEGRATION),
  ])
}
