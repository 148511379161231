import React from 'react'
import { connect } from 'react-redux'
import FavPages from './FavPages'
// import Search from './Search'
import LanguageSwitcher from './LanguageSwitcher'
// import Actions from './Actions'
import UserMenu from './UserMenu'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  direction: settings.direction,
})

const TopBar = ({ direction }) => {
  return (
    <div className={style.topbar}>
      <div className={`${direction === 'rtl' ? 'ml-3 ml-md-1' : 'mr-3 mr-md-1'}`}>
        <FavPages />
      </div>
      {/* <div className={`${direction === 'rtl' ? 'ml-3 ml-md-1' : 'mr-3 mr-md-1'}`}>
        <Search />
      </div> */}
      {/* <div
        className={`${
          direction === 'rtl' ? 'ml-3 mr-auto d-none d-sm-block' : 'mr-3 ml-auto d-none d-sm-block'
        }`}
      >
        <Actions />
      </div> */}
      <div
        className={`${direction === 'rtl' ? 'ml-3 mr-auto d-none d-sm-block' : 'mr-3 ml-auto d-none d-sm-block'}`}
      >
        <LanguageSwitcher />
      </div>
      <div className="">
        <UserMenu />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(TopBar)
