import {
  faSmile,
  faTachometerFast,
  faGlobe,
  faInfoCircle,
  faPennant,
  faQrcode,
  faAt,
  faLink,
  faFolder,
  faLineColumns,
  faShapes,
  faSignOut,
  faCog,
  faTimes,
  faExternalLinkAlt,
  faAngleLeft,
  faTags,
  faHome,
  faLifeRing,
  faHistory,
  faLockAlt,
  faGifts,
  faUserCircle,
  faChartNetwork,
  faPoundSign,
  faUsers,
  faRepeat,
  faCoins,
  faAward,
  faUserSlash,
  faSearch,
  faEye,
  faPencil,
  faTrashAlt,
  faEllipsisV,
  faClock,
  faLanguage,
  faPaintBrush,
  faHeartRate,
  faList,
  faUsersCrown,
  faStoreAlt,
  faUser,
  faMoneyCheckAlt,
  faSync,
  faGiftCard,
  faGift,
  faCoin,
  faMoneyBillWave,
  faHomeAlt,
  faSpinner,
  faCogs,
  faQuestionCircle,
  faStamp,
  faChevronRight,
  faChevronLeft,
  faCommentsAlt,
  faStarHalfAlt,
  faMegaphone,
  faStarExclamation,
  faShoppingCart,
  faHandsHeart,
  faHandsUsd,
  faExchangeAlt,
  faHandHoldingUsd,
  faPercent,
  faSignIn,
  faHandHoldingHeart,
  faGrinStars,
  faSmileBeam,
  faUserPlus,
  faCashRegister,
  faCommentAltDollar,
  faBan,
  faBirthdayCake as faBirthdayCakeRegular,
  faSms,
  faStreetView,
  faHandshake as faHandshakeRegular,
  faBadgePercent,
  faHeartCircle,
  faCrown as faCrownRegular,
  faPlusSquare,
  faCreditCard,
  faEdit as faEditRegular,
  faCloudDownloadAlt,
  faAd,
  faBook,
  faBarcodeAlt,
  faBoxFull,
  faBagsShopping,
  faHandHoldingBox,
  faWallet,
  faBellOn,
  faCheckCircle,
  faInboxIn,
  faInboxOut,
  faUserCrown,
  faRocketLaunch,
  faUpload,
  faPlus,
  faHands,
  faHandReceiving,
  faBookmark,
  faExclamationCircle,
  faPaperPlane,
  faExchange,
  faPercentage,
} from '@fortawesome/pro-regular-svg-icons'

import { faSmile as faSmileLight } from '@fortawesome/pro-light-svg-icons'


import {
  faBirthdayCake,
  faCommentAltDots,
  faCrown,
  faPauseCircle,
  faMegaphone as faMegaphoneSolid,
  faBadgeCheck,
  faBadgePercent as faBadgePercentSolid,
  faBadgeDollar,
  faCoin as faCoinSolid,
  faBurgerSoda,
  faCroissant,
  faHatChef,
  faCheeseburger,
  faPie,
  faPopcorn,
  faSeedling,
  faSunglasses,
  faBoot,
  faGamepadAlt,
  faFlowerTulip,
  faBadge,
  faBookmark as faBookmarkSolid,
  faUserAltSlash,
  faReceipt,
  faShoppingBasket,
  faSignalAlt,
  faSackDollar,
  faGripHorizontal,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faEdit,
  faMoon,
  faSun,
  faTrashAlt as faTrashAltSolid,
  faStarHalfAlt as faStarHalfAltSolid,
  faGlobe as faGlobeSolid,
  faGifts as faGiftsSolid,
  faStamp as faStampSolid,
  faHandshake,
  faPlusCircle,
  faDownload,
  faCopy,
  faMugHot,
  faPizzaSlice,
  faIceCream,
  faSprayCan,
  faSpa,
  faCut,
  faDumbbell,
  faPrescription,
  faTshirt,
  faGem,
  faPaw,
  faCouch,
  faCheck,
  faPills,
} from '@fortawesome/free-solid-svg-icons'
import { faAngry, faBell, faFrown, faMeh, faStar } from '@fortawesome/free-regular-svg-icons'

import {
  faInstagram,
  faYoutube,
  faFacebook,
  faFacebookSquare,
  faLinkedin,
  faAngellist,
  faProductHunt,
  faMedium,
  faWhatsapp,
  faPinterest,
  faTwitter,
  faGoogle,
  faAppStoreIos,
  faAppStore,
  faSnapchat,
  faGithub,
  faFacebookMessenger,
  faBehance,
  faDribbble,
  faTumblrSquare,
  faReddit,
  faTelegram,
  faTelegramPlane,
  faCcApplePay,
  faCcPaypal,
  faSnapchatGhost,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons'

import { library, config } from '@fortawesome/fontawesome-svg-core'

config.autoAddCss = true

library.add(
  faSmile,
  faBell,
  faTachometerFast,
  faGlobe,
  faInfoCircle,
  faPennant,
  faQrcode,
  faTimes,
  faLineColumns,
  faUser,
  faShapes,
  faAt,
  faLink,
  faFolder,
  faSignOut,
  faCog,
  faExternalLinkAlt,
  faAngleLeft,
  faTags,
  faHome,
  faLifeRing,
  faHistory,
  faLockAlt,
  faGifts,
  faUserCircle,
  faChartNetwork,
  faPoundSign,
  faUsers,
  faRepeat,
  faCoins,
  faAward,
  faUserSlash,
  faSearch,
  faEye,
  faPencil,
  faTrashAlt,
  faEllipsisV,
  faClock,
  faLanguage,
  faPaintBrush,
  faHeartRate,
  faMoneyCheckAlt,
  faSync,
  faGiftCard,
  faGift,
  faCoin,
  faMoneyBillWave,
  faList,
  faUsersCrown,
  faStoreAlt,
  faHomeAlt,
  faSpinner,
  faCogs,
  faQuestionCircle,
  faInstagram,
  faYoutube,
  faFacebook,
  faFacebookSquare,
  faLinkedin,
  faAngellist,
  faProductHunt,
  faMedium,
  faWhatsapp,
  faPinterest,
  faTwitter,
  faGoogle,
  faAppStoreIos,
  faAppStore,
  faSnapchat,
  faGithub,
  faFacebookMessenger,
  faBehance,
  faDribbble,
  faTumblrSquare,
  faReddit,
  faTelegram,
  faTelegramPlane,
  faCcApplePay,
  faCcPaypal,
  faSnapchatGhost,

  faStamp,
  faChevronRight,
  faChevronLeft,
  faCommentsAlt,
  faStarHalfAlt,

  faSmileLight,

  faMoon,
  faSun,
  faMegaphone,
  faStarExclamation,
  faShoppingCart,
  faHandsHeart,
  faHandsUsd,
  faExchangeAlt,
  faHandHoldingUsd,
  faPercent,
  faSignIn,
  faEdit,
  faTrashAltSolid,
  faHandHoldingHeart,
  faStarHalfAltSolid,
  faCommentAltDots,
  faGrinStars,
  faSmileBeam,
  faMeh,
  faFrown,
  faAngry,
  faGlobeSolid,
  faUserPlus,
  faCashRegister,
  faGiftsSolid,
  faCommentAltDollar,
  faBan,
  faCrown,
  faStampSolid,
  faHandshake,
  faPauseCircle,
  faBirthdayCake,
  faBirthdayCakeRegular,
  faMegaphoneSolid,
  faSms,
  faStreetView,
  faHandshakeRegular,
  faBadgePercent,
  faHeartCircle,
  faCrownRegular,
  faPlusSquare,
  faCreditCard,
  faEditRegular,
  faCloudDownloadAlt,
  faAd,
  faBook,
  faBarcodeAlt,
  faBoxFull,
  faBagsShopping,
  faHandHoldingBox,
  faWallet,
  faBellOn,
  faCheckCircle,
  faPlusCircle,
  faStar,
  faInboxIn,
  faInboxOut,
  faUserCrown,
  faBadgeCheck,
  faBadgePercentSolid,
  faBadgeDollar,
  faDownload,
  faCopy,
  faRocketLaunch,
  faUpload,
  faCoinSolid,
  faMugHot,
  faPizzaSlice,
  faIceCream,
  faBurgerSoda,
  faCroissant,
  faHatChef,
  faCheeseburger,
  faPie,
  faPopcorn,
  faSeedling,
  faSprayCan,
  faSpa,
  faCut,
  faDumbbell,
  faPrescription,
  faTshirt,
  faGem,
  faSunglasses,
  faBoot,
  faGamepadAlt,
  faPaw,
  faCouch,
  faFlowerTulip,
  faCheck,
  faPills,
  faFacebookF,
  faPlus,
  faBadge,
  faHands,
  faHandsHeart,
  faHandsUsd,
  faHandReceiving,
  faBookmark,
  faBookmarkSolid,
  faUserAltSlash,
  faExclamationCircle,
  faPaperPlane,
  faExchange,
  faPercentage,
  faReceipt,
  faShoppingBasket,
  faSignalAlt,
  faSackDollar,
  faGripHorizontal,
)
