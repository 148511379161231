import actions from './actions'

const initialState = {
    stats: {
        totalRewards : 0, 
        customers : 0, 
        totalPoints : 0, 
        totalVisits: 0 , 
        totalRevenuGC : 0,
        totalLoyaltyRevenue: 0,
        giftcCards: 0
    },
    revenue: [],
    chartsLoading: false,
    customers: [],
    points: [],
    redeemedRewards: [],
    earnedRewards: [],
    giftCards: {},
    burnedPoints: [],
    loading: false,
}

export default function giftCardsReducer(state = initialState, action) {  
    switch (action.type) {
        case actions.SET_STATE:
            return { ...state, ...action.payload }
        case actions.RESET_STATE:
            return { ...initialState }
        default:
        return state
    }
}