import React from 'react'
import i18n from 'i18next'

function WarningMessage() {
  return (
    <div dir="auto">
      {`${i18n.t('branches:notification.watchIt1')}`}
      <a className="vb__utils__link" target="_blank" rel="noreferrer" href={i18n.t('branches:notification.watchItLink')}>
        {i18n.t('branches:notification.watchIt2')}
      </a>
      {`${i18n.t('branches:notification.watchIt3')}`}
    </div>
  )
}


export default WarningMessage
