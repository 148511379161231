import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import i18n from 'i18next'
import { getWallets, deleteWallet, updateWallet, addWallet, sendWalletMessage } from '../service'
import actions from './actions'

export function* GET_WALLETS({ payload }) {
  yield put({
    type: 'wallets/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(getWallets, payload)
  if (data.status) {
    const { wallets } = data.data

    yield put({
      type: 'wallets/SET_STATE',
      payload: {
        wallets,
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'wallets/SET_STATE',
      payload: {
        wallets: [],
        loading: false,
      },
    })
  }
}

export function* UPDATE_WALLET({ payload }) {
  yield put({
    type: 'wallets/SET_STATE',
    payload: {
      creating: true,
    },
  })
  const data = yield call(updateWallet, payload)
  if (data.status) {
    yield put({
      type: 'wallets/SET_STATE',
      payload: {
        creating: false,
      },
    })
    yield put({
      type: 'wallets/GET_WALLETS',
      payload: {
        businessId: payload.businessId,
      },
    })
    // notification.success({
    //   message: 200,
    //   description: i18n.t('wallet:notification.walletUpdateTrue'),
    //   duration: 3,
    // })
  } else {
    yield put({
      type: 'wallets/SET_STATE',
      payload: {
        creating: false,
        selected: null
      },
    })
    notification.warning({
      message: 500,
      description: i18n.t('wallet:notification.walletUpdateFalse'),
      duration: 3,
    })
  }
}

export function* ADD_WALLET({ payload }) {
  yield put({
    type: 'wallets/SET_STATE',
    payload: {
      loading: true,
      creating: true,
      selected: null
    },
  })
  const response = yield call(addWallet, payload)
  const { data } = response
  // eslint-disable-next-line no-underscore-dangle
  if (data?._id) {
    payload.func()
    yield put({
      type: 'wallets/SET_STATE',
      payload: {
        loading: false,
        creating: false,
        selected: data
      },
    })
    yield put({
      type: 'wallets/GET_WALLETS',
      payload: {
        businessId: payload.businessId,
      },
    })
  } else {
    yield put({
      type: 'wallets/SET_STATE',
      payload: {
        loading: false,
        creating: false,
        selected: null
      },
    })
    if(response.status === 403) {
      notification.warning({
        description: i18n.t('wallet:notification.walletNameUsed'),
        duration: 3,
      })
    }
  }
  if (!response.status) {
    yield put({
      type: 'wallets/SET_STATE',
      payload: {
        loading: false,
        creating: false,
      },
    })
  }
}

export function* DELETE_WALLET({ payload }) {
  yield put({
    type: 'wallets/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(deleteWallet, payload)
  if (response.status) {
    yield put({
      type: 'wallets/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'wallets/GET_WALLETS',
    })
    notification.success({
      message: 200,
      description: i18n.t('wallet:notification.walletDeleteTrue'),
      duration: 3,
    })
  } else {
    notification.warning({
      message: 500,
      description: i18n.t('wallet:notification.walletDeleteFalse'),
      duration: 3,
    })
  }
}

export function* SEND_WALLET_MESSAGE({ payload }) {
  const response = yield call(sendWalletMessage, payload)
  if (response.status === true) {
    notification.success({
      message: 200,
      description: i18n.t('wallet:notification.walletMessageTrue'),
      duration: 3,
    })
  } else {
    notification.warning({
      message: 500,
      description: i18n.t('wallet:notification.walletMessageFalse'),
      duration: 3,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_WALLETS, GET_WALLETS),
    takeEvery(actions.DELETE_WALLET, DELETE_WALLET),
    takeEvery(actions.UPDATE_WALLET, UPDATE_WALLET),
    takeEvery(actions.ADD_WALLET, ADD_WALLET),
    takeEvery(actions.SEND_WALLET_MESSAGE, SEND_WALLET_MESSAGE),
  ])
}
