import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import i18n from 'i18next'
import { getCustomer, banCustomer } from '../service'
import actions from './actions'

export function* GET_CUSTOMER({ payload }) {
  yield put({
    type: 'customers/SET_STATE',
  })
  const data = yield call(getCustomer, payload)
  const customer = data.data

  if (data.status) {
    yield put({
      type: 'customers/SET_STATE',
      payload: {
        customer,
      },
    })
  } else {
    yield put({
      type: 'customers/SET_STATE',
      payload: {
        customer: {},
      },
    })
  }
}

export function* BLOCK_CUSTOMER({ payload }) {
  const data = yield call(banCustomer, payload)
  if (data.status) {
    yield put({
      type: 'customer/UPDATE_CUSTOMER',
      payload: {
        banned: payload.banned,
        id: payload.id,
        businessId: payload.businessId,
      },
    })
    notification.success({
      message: 200,
      description: i18n.t(`customers:notification.banned${payload.banned}`),
      duration: 3,
    })
    yield put({
      type: 'customers/GET_CUSTOMER',
      payload,
    })
  } else {
    notification.warning({
      message: 500,
      description: i18n.t(`customers:notification.bannederror`),
      duration: 3,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CUSTOMER, GET_CUSTOMER),
    takeEvery(actions.BLOCK_CUSTOMER, BLOCK_CUSTOMER),
  ])
}
