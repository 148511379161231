import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import i18n from "i18next"

import {
  addCategory,
  addProduct,
  deleteCategory,
  deleteProduct,
  getCategories,
  getMenuStats,
  getProducts,
  syncMenu,
  updateCategory,
  updateProduct,
} from '../service'
import actions from './actions'

export function* GET_MENU_STATS({ payload }) {
  yield put({
    type: 'products/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(getMenuStats, payload)
  if (data.status) {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        loading: false,
        stats: data.data
      },
    })

  } else {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* SYNC_MENU({ payload }) {
  yield put({
    type: 'products/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(syncMenu, payload)

  if (data.status) {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'products/GET_CATEGORIES',
    })
  } else {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 500,
      description: i18n.t('loyaltyProgram:notification.syncError'),
      duration: 3
    })
  }
}

export function* GET_PRODUCTS({ payload }) {
  yield put({
    type: 'products/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(getProducts, payload)

  if (data.status) {
    const { products } = data.data
    yield put({
      type: 'products/SET_STATE',
      payload: {
        products,
        pagination: {
          total: data.data.totalDocs,
          current: data.data.page,
          pageSize: data.data.limit,
        },
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        products: [],
        loading: false,
      },
    })
  }
}

export function* ADD_PRODUCT({ payload }) {
  yield put({
    type: 'products/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(addProduct, payload)
  if (data.status === true) {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'products/GET_CATEGORIES',
    })
    notification.success({
      message: 200,
      description: i18n.t('products:notification.productAddTrue'),
      duration: 3
    })
  } else {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 500,
      description: i18n.t('products:notification.productAddFalse'),
      duration: 3
    })
  }
}

export function* UPDATE_PRODUCT({ payload }) {
  yield put({
    type: 'products/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(updateProduct, payload)
  if (data.status) {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'products/GET_CATEGORIES',
    })
    notification.success({
      message: 200,
      description: i18n.t('products:notification.productUpdateTrue'),
      duration: 3
    })
  } else {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 500,
      description: i18n.t('products:notification.productUpdateFalse'),
      duration: 3
    })
  }
}

export function* DELETE_PRODUCT({ payload }) {
  yield put({
    type: 'products/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(deleteProduct, payload)
  if (response.status) {
    yield put({
      type: 'products/DELETE_PRODUCT_ELEM',
      payload: {
        loading: false,
        id: payload.id,
      },
    })

    // notification.success({
    //   message: 200,
    //   description: i18n.t('products:notification.deleteTrue'),
    //   duration: 3
    // })
  } else {
    // notification.warning({
    //   message: 500,
    //   description: i18n.t('products:notification.deleteFalse'),
    //   duration: 3
    // })
  }
}

export function* GET_CATEGORIES_LIST({ payload }) {
  yield put({
    type: 'products/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(getCategories, {...payload, list: true})
  if (data.status) {
    const { categoriesList } = data.data
    yield put({
      type: 'products/SET_STATE',
      payload: {
        categoriesList,
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        categoriesList: [],
        loading: false,
      },
    })
  }
}

export function* GET_CATEGORIES({ payload }) {
  yield put({
    type: 'products/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(getCategories, payload)

  if (data.status) {
    const { categories } = data.data
    yield put({
      type: 'products/SET_STATE',
      payload: {
        categories,
        pagination: {
          total: data.data.totalDocs,
          current: data.data.page,
          pageSize: data.data.limit,
          },
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        categories: [],
        loading: false,
      },
    })
  }
}

export function* ADD_CATEGORY({ payload }) {
  yield put({
    type: 'products/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(addCategory, payload)
  if (data.status === true) {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'products/GET_CATEGORIES_LIST',
    })
    notification.success({
      message: 200,
      description: i18n.t('products:notification.categoryAddTrue'),
      duration: 3
    })
  } else {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 500,
      description: i18n.t('products:notification.categoryAddFalse'),
      duration: 3
    })
  }
}

export function* UPDATE_CATEGORY({ payload }) {
  yield put({
    type: 'products/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(updateCategory, payload)
  if (data.status) {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'products/GET_CATEGORIES',
    })
    notification.success({
      message: 200,
      description: i18n.t('products:notification.categoryUpdateTrue'),
      duration: 3
    })
  } else {
    yield put({
      type: 'products/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 500,
      description: i18n.t('products:notification.categoryUpdateFalse'),
      duration: 3
    })
  }
}

export function* DELETE_CATEGORY({ payload }) {
  yield put({
    type: 'products/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(deleteCategory, payload)
  if (response.status) {
    yield put({
      type: 'products/DELETE_CATEGORY_ELEM',
      payload: {
        loading: false,
        id: payload.id,
      },
    })

    // notification.success({
    //   message: 200,
    //   description: i18n.t('products:notification.deleteTrue'),
    //   duration: 3
    // })
  } else {
    // notification.warning({
    //   message: 500,
    //   description: i18n.t('products:notification.deleteFalse'),
    //   duration: 3
    // })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PRODUCTS, GET_PRODUCTS),
    takeEvery(actions.ADD_PRODUCT, ADD_PRODUCT),
    takeEvery(actions.UPDATE_PRODUCT, UPDATE_PRODUCT),
    takeEvery(actions.DELETE_PRODUCT, DELETE_PRODUCT),
    takeEvery(actions.GET_CATEGORIES, GET_CATEGORIES),
    takeEvery(actions.ADD_CATEGORY, ADD_CATEGORY),
    takeEvery(actions.UPDATE_CATEGORY, UPDATE_CATEGORY),
    takeEvery(actions.DELETE_CATEGORY, DELETE_CATEGORY),
    takeEvery(actions.GET_CATEGORIES_LIST, GET_CATEGORIES_LIST),
    takeEvery(actions.SYNC_MENU, SYNC_MENU),
    takeEvery(actions.GET_MENU_STATS, GET_MENU_STATS),
  ])
}
