const actions = {
    SET_STATE: 'business/SET_STATE',
    RESET_STATE: 'business/RESET_STATE',
    GET_BUSINESS: 'business/GET_BUSINESS',
    UPDATE_BUSINESS: 'business/UPDATE_BUSINESS',
    SET_BUSINESS_ATTR: 'business/SET_BUSINESS_ATTR',
    ADD_MEMBER: 'business/ADD_MEMBER',
    DELETE_MEMBER: 'business/DELETE_MEMBER',
    UPDATE_MEMBER: 'business/UPDATE_MEMBER',
    GET_TEAM: 'business/GET_TEAM',
    GET_CASHIERS: 'business/GET_CASHIERS',
    DELETE_CASHIER: 'business/DELETE_CASHIER',
    UPDATE_CASHIER: 'business/UPDATE_CASHIER',
    UPDATE_CASHIER_PASSWORD: 'business/UPDATE_CASHIER_PASSWORD',
    ADD_CASHIER: 'business/ADD_CASHIER',


}

export default actions