import { notification } from 'antd'
import axios from 'axios'
import cookie from 'js-cookie'
import decodeJwt from 'jwt-decode'
import i18n from 'i18next'
import { API_ADDRESS } from './config'

export const signupToApi = async (mail, password) => {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/auth/signup`, { email: mail, password })
    return data
  } catch ({ response }) {
    if (!response) {
      notification.open({
        message: i18n.t('apiMessages:apiConnectionError.title'),
        description: i18n.t('apiMessages:apiConnectionError.error'),
        duration: 5,
      })
      return false
    }
    return response.data
  }
}

export const updateFCM = async (fcm) => {
  try {
    const { data } = await axios.patch(`${API_ADDRESS}/account/fcm-token`, fcm, {
      headers: { Authorization: cookie.get('token') },
    })
    return data
  } catch ({ response }) {
    if (!response) {
      notification.open({
        message: i18n.t('apiMessages:apiConnectionError.title'),
        description: i18n.t('apiMessages:apiConnectionError.error'),
        duration: 5,
      })
      return false
    }
    return response.data
  }
}

export const loginToApi = async (email, password) => {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/auth/login`, { email, password })
    const { token } = data
    cookie.set('token', token, { expires: 7 })
    return {
      ...data,
      ...decodeJwt(token),
    }
  } catch ({ response }) {
    if (!response) {
      notification.open({
        message: i18n.t('apiMessages:apiConnectionError.title'),
        description: i18n.t('apiMessages:apiConnectionError.error'),
        duration: 5,
      })
      return false
    }
    return response.data
  }
}

export const getAccountDetails = async () => {
  try {
    const { data } = await axios.get(`${API_ADDRESS}/account`, {
      headers: { Authorization: cookie.get('token') },
    })
    return data
  } catch ({ response }) {
    if (!response) {
      notification.open({
        message: i18n.t('apiMessages:apiConnectionError.title'),
        description: i18n.t('apiMessages:apiConnectionError.error'),
        duration: 5,
      })
      return false
    }
    return response.data
  }
}

export const requestReset = async (email) => {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/auth/resetpassword`, { email })
    return data
  } catch ({ response }) {
    if (!response) {
      notification.open({
        message: i18n.t('apiMessages:apiConnectionError.title'),
        description: i18n.t('apiMessages:apiConnectionError.error'),
        duration: 5,
      })
      return false
    }
    return response.data
  }
}

export const reset = async (password, resetPasswordToken) => {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/auth/reset`, { password, resetPasswordToken })
    return data
  } catch ({ response }) {
    if (!response) {
      notification.open({
        message: i18n.t('apiMessages:apiConnectionError.title'),
        description: i18n.t('apiMessages:apiConnectionError.error'),
        duration: 5,
      })
      return false
    }
    return response.data
  }
}

export const resetPassword = async (params) => {
  try {
    const { data } = await axios.patch(`${API_ADDRESS}/account/reset-password`, params, {
      headers: { Authorization: cookie.get('token') },
    })
    return data
  } catch ({ response }) {
    if (!response) {
      notification.open({
        message: i18n.t('apiMessages:apiConnectionError.title'),
        description: i18n.t('apiMessages:apiConnectionError.error'),
        duration: 5,
      })
      return false
    }
    return response.data
  }
}

export async function logout() {
  cookie.remove('token')
  return true
}

export const renewAuthUser = async () => {
  try {
    const { data } = await axios.post(
      `${API_ADDRESS}/auth/renew`,
      {},
      {
        headers: { Authorization: cookie.get('token') },
      },
    )
    const { token } = data
    if (token) {
      cookie.set('token', token, { expires: 7 })
      return {
        ...data,
        ...decodeJwt(token),
      }
    }
    return {
      ...data,
    }
  } catch ({ response }) {
    cookie.remove('token')
    if (!response) {
      notification.open({
        message: i18n.t('apiMessages:apiConnectionError.title'),
        description: i18n.t('apiMessages:apiConnectionError.error'),
        duration: 5,
      })
      return false
    }
    return response.data
  }
}

export const updateUser = async (params) => {
  try {
    const { data } = await axios.patch(`${API_ADDRESS}/account`, params, {
      headers: { Authorization: cookie.get('token') },
    })
    return data
  } catch ({ response }) {
    return response.data
  }
}

export const getOnBoarding = async (params) => {
  try {
    const { data } = await axios.get(`${API_ADDRESS}/businesses/${params.businessId}/onboarding`, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (response) {
    return response
  }
}
export const requestPlanUpgrade = async (params) => {
  try {
    const { data } = await axios.post(`${API_ADDRESS}/plans/request`, params, {
      headers: { Authorization: cookie.get('token'), 'content-type': 'application/json' },
    })
    return data
  } catch (response) {
    return response
  }
}

export const loginOTP = async (email, password) => {
  const {data} = await axios.post(`${API_ADDRESS}/auth/loginOTP`, { email, password })
  return data;
}

export const checkOTP = async (email, password, verificationCode) => {
  try {
    const {data} = await axios.post(`${API_ADDRESS}/auth/checkVerification`, { email, password, verificationCode })
    const { token } = data
    cookie.set('token', token, { expires: 7 })
    return {
      ...data,
      ...decodeJwt(token),
    }
  } catch ({ response }) {
    if (!response) {
      notification.open({
        message: i18n.t('apiMessages:apiConnectionError.title'),
        description: i18n.t('apiMessages:apiConnectionError.error'),
        duration: 5,
      })
      return false
    }
    return response.data
  }
}