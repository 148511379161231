import actions from './actions'

const initialState = {
    feedbacks: [],
    loading: false,
    pagination: {
      total: null,
      current: 1,
      pageSize: 10
    },
}

export default function feedbacksReducer(state = initialState, action) {      
    switch (action.type) {
      case actions.SET_STATE:
        return { ...state, ...action.payload }
      case actions.RESET_STATE:
        return { ...initialState }
      default:
        return state
    }
  }