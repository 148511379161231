/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
    customers: [],
    numOfMembers: 0,
    loading: false,
    selected: null,
    pagination: {
      total: null,
      current: 1,
      pageSize: 10
    },
    newCustomers: []
}

export default function customersReducer(state = initialState, action) {      
    switch (action.type) {
      case actions.SET_STATE:
        return { ...state, ...action.payload }
      case actions.RESET_STATE:
        return { ...initialState }
      case actions.UPDATE_CUSTOMER: {      
        const {customers} = state;
        const data = {banned: action.payload.banned};
        const {ids} = action.payload
        const {id} = action.payload
        if (ids && ids.length) {
          const indexes = customers.map((customer, index) => {
            if (ids.includes(customer.customer._id))
              return index;
          });

          indexes.map(i => {
            customers[i] = {
              ...customers[i], 
              customer: {
                ...data,
                ...customers[i].customer
              }
            }
          })
      } else {
          const index = customers.map((customer , i) => {            
            if (id.includes(customer.customer._id))
              return i;
          });          
          index.map(i => {
            if(i !==   undefined ){
              customers[i] = {
                ...customers[i], 
                ...data,
                customer: {
                  ...customers[i].customer
                }
              }
            }
              
          })
        };
        return { 
          ...state,
          customers: [...customers]
        }
      } 
      // case actions.SELECTED_CUSTOMER:
      //   let selected = state.selected;
      //   selected = { ...selected , ...action.payload.selected }
      //   return {
      //     ...state,
      //     selected: {...selected},
      //     }
      // case actions.ADD_CUSTOMER_ELEM:
      //   return {
      //     ...state,
      //     customers: [{...state.selected}, ...state.customers],
      //     }
      // case actions.DELETE_CUSTOMER_ELEM:
      //   return {
      //     ...state,
      //     customers: [{...state.selected}, ...state.customers],
      //   }
      default:
        return state
    }
  }