import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import LanguageSwitcher from 'components/TopBar/LanguageSwitcher'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
  isAuthTopbar: settings.isAuthTopbar,
})

const AuthLayout = ({
  children,
  isGrayTopbar,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
  isAuthTopbar,
  t,
}) => {
  return (
    <Layout className="vb__layout">
      <Layout.Content>
        <div
          className={classNames(`${style.container}`, {
            vb__layout__squaredBorders: isSquaredBorders,
            vb__layout__cardsShadow: isCardShadow,
            vb__layout__borderless: isBorderless,
            [style.white]: authPagesColor === 'white',
            [style.gray]: authPagesColor === 'gray',
          })}
          style={{
            backgroundImage:
              authPagesColor === 'image' ? 'url(resources/images/content/photos/8.jpeg)' : '',
          }}
        >
          {isAuthTopbar && (
            <div
              className={classNames(`${style.topbar}`, {
                [style.topbarGray]: isGrayTopbar,
              })}
            >
              <div className={style.logoContainer}>
                <div className={style.logo}>
                  <img src="/resources/images/logos/logo.png" alt="Logo img" height="30" />
                </div>
              </div>
              <div className="d-none d-sm-block">
                <LanguageSwitcher />
              </div>
            </div>
          )}
          <div className="mb-5">{children}</div>
          <div className="mt-auto pb-5 pt-5">
            <ul
              className={`${style.footerNav} list-unstyled d-flex mb-0 flex-wrap justify-content-center`}
            >
              <li>
                <a href="https://boonus.app/contact/">{t('auth.login.footer.contactUs')}</a>
              </li>
            </ul>
            <div className="text-gray-4 text-center">
              {t('footer:copyRight1')}{' '}
              <a className="vb__utils__link" href="https://resal.me/">
                {t('footer:copyRight2')}
              </a>
              {t('footer:copyRight3', { date: new Date().getFullYear() })}
            </div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withTranslation(['component', 'footer'])(withRouter(connect(mapStateToProps)(AuthLayout)))
