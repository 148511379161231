import localeAntd from 'antd/es/locale/ar_EG'

const messages = {
  'topBar.issuesHistory': 'Issues',
  'topBar.projectManagement': 'Projects',
  'topBar.typeToSearch': 'بحث...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'الحالة',
  'topBar.profileMenu.hello': 'مرحبا',
  'topBar.profileMenu.billingPlan': 'الخطة',
  'topBar.profileMenu.role': 'الدور',
  'topBar.profileMenu.email': 'البريد الالكتروني',
  'topBar.profileMenu.phone': 'الهاتف',
  'topBar.profileMenu.editProfile': 'تعديل معلومات الحساب',
  'topBar.profileMenu.logout': 'تسجيل خروج',
}

export default {
  locale: 'ar-EG',
  localeAntd,
  messages
}
