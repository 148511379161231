/* eslint-disable array-callback-return */
/* eslint-disable no-case-declarations */
import actions from './actions'

const initialState = {
    business: null,
    loading: false,
    cashiers: [],
    selected: null,
    creating:false,
    loadingBranches: false,
    isOnboardingComplete: false,
    loadingMembers: false,
    loadingCashiers: false
}

export default function businessReducer(state = initialState, action) {  
    switch (action.type) {
      case actions.SET_STATE:
        return { ...state, ...action.payload }
      case actions.RESET_STATE:
        return { ...initialState }
      case actions.SELECTED_BUSINESS:
        let {selected} = state;
        selected = { ...selected , ...action.payload.selected }
        return {
          ...state,
          selected: {...selected},
          }
      case actions.SET_BUSINESS_ATTR:
        const {business} = state;
        Object.keys(action.payload).map(k =>{ 
          business[k] = action.payload[k]
        })
        return {
          ...state,
          business,
        }
      default:
        return state
    }
  }