import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import style from './style.module.scss'
import { NEW_FOOTER  } from '../../services/config'

const mapStateToProps = ({ settings }) => ({ settings })

const Footer = ({ settings: { isContentMaxWidth } }) => {
  const { t } = useTranslation('footer')
  return (
    <div
      className={classNames(style.footer, {
        [style.footerFullWidth]: !isContentMaxWidth,
      })}
    >
      <div className={style.inner}>
        <div className="row">
          <div className="col-md-6">
            <p>
              {t('text')}
            </p>
            <p>
              {t('copyRight1')}{" "} 
              <a className="vb__utils__link" href="https://resal.me/">
                {t('copyRight2')}
              </a>
              {t('copyRight3', {date: new Date().getFullYear()})}
            </p>
            <p className="mb-0 mt-3">
              <a className="vb__utils__link" href="https://boonus.app/policies-terms/" target="_blank" rel="noopener noreferrer">
                {t('privacyPolicy')}
              </a>
              {' | '}
              <a className="vb__utils__link" href="https://boonus.app/faqs/" target="_blank" rel="noopener noreferrer">
                {t('faq')}
              </a>
              {' | '}
              <a className="vb__utils__link" href="https://boonus.app/contact/" target="_blank" rel="noopener noreferrer">
                {t('contactUs')}
              </a>
            </p>
          </div>
          <div className="col-md-6 d-flex flex-row-reverse align-items-end">
            <img
              className="mx-2"
              src={NEW_FOOTER? "/resources/images/logos/logo-footer.svg" : "/resources/images/logos/logo-footer.png"}
              alt="Logo img"
              height="30"
            />
            <a className={`${style.icons} bg-primary mx-2`} href="https://twitter.com/Resal_Boonus"><FontAwesomeIcon className="text-light" icon={['fab', 'twitter']} /></a>
            <a className={`${style.icons} bg-primary mx-2`} href="https://www.instagram.com/resal_boonus/"><FontAwesomeIcon className="text-light" icon={['fab', 'instagram']} /></a>
            <a className={`${style.icons} bg-primary mx-2`} href="https://www.linkedin.com/company/boonusapp"><FontAwesomeIcon className="text-light" icon={['fab', 'linkedin']} /></a>
            <a className={`${style.icons} bg-primary mx-2`} href="https://www.youtube.com/channel/UCcVKKZh_8jz6m_SttOnYjjw"><FontAwesomeIcon className="text-light" icon={['fab', 'youtube']} /></a>
            <a className={`${style.icons} bg-primary mx-2`} href="https://www.facebook.com/boonusapp "><FontAwesomeIcon className="text-light" icon={['fab', 'facebook-f']} /></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Footer)
