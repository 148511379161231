import actions from './actions'

const initialState = {
  customer: {
    customer: {
      city: '-',
      district: '-',
      workField: '-',
      birthDate: '-',
      firstName: '-',
      lastName: '-',
      gender: '-',
      phone: {
        number: '-',
        countryCode: '',
      },
      email: '-',
    },
    lastVisit: '-',
    lastAmountSpent: 0,
    numOfVisitsLastMonth: '-',
    averageSpending: 0,
    totalReviews: 0,
    rewardsGained: 0,
    rewardsRedeemed: 0,
    redemptionRate: '0 %',
    totalSpendings: 0,
    totalVisits: 0,
    satisfaction: 0,
    totalPoints: 0,
    currentLevel: '-',
    totalLoyaltySeals: 0,
    totalNumberOfStamps: 0,
  },
}

export default function customerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.RESET_STATE:
      return { ...initialState }
    case actions.UPDATE_CUSTOMER: {
      return {
        ...state,
        banned: action.payload.banned,
      }
    }
    default:
      return state
  }
}
