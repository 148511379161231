import cookie from 'js-cookie'
import axios from 'axios'
import { API_ADDRESS  } from '../../services/config'


export async function getCampaigns(params){
  try{
    const {
      data
    } = await axios.post(`${API_ADDRESS}/campaigns/get/${params?.id ? params.id : '' }`, params,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch(response){
    return response;
  } 
}

export async function addCampaign(params){ 
  try{
    const { data } = await axios.post(`${API_ADDRESS}/campaigns/`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });
    return data;
  }catch({response}){    
    return response;
  }
}

export async function updateCampaign(params){  
  try{
    const { data } = await axios.patch(`${API_ADDRESS}/campaigns/${params.id}`, params ,{
      headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  },
    });

    return data;
  }catch({response}){    
    return response;
  }
}

export async function deleteCampaign(params){  
  try{
    const { data } = await axios.delete(`${API_ADDRESS}/campaigns/${params.id}`,
    { headers: { Authorization: cookie.get('token') , 'content-type' : 'application/json'  }});
    return data;
  }catch({response}){
    return response;
  }
}