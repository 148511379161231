import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// import locals
import menuEN from './services/menu/locale/en.json'
import menuAR from './services/menu/locale/ar.json'
import footerEN from './components/Footer2/locale/en.json'
import footerAR from './components/Footer2/locale/ar.json'
import dashboardEN from './pages/dashboard/locale/en.json'
import dashboardAR from './pages/dashboard/locale/ar.json'
import componentEN from './components/locale/en'
import componentAR from './components/locale/ar'
import customersEN from './pages/customers/locale/en.json'
import customersAR from './pages/customers/locale/ar.json'
import customerPageEN from './pages/single-customer/locale/en.json'
import customerPageAR from './pages/single-customer/locale/ar.json'
import feedbacksEN from './pages/feedback/locale/en.json'
import feedbacksAR from './pages/feedback/locale/ar.json'
import transactionsEN from './pages/transactions/locale/en.json'
import transactionsAR from './pages/transactions/locale/ar.json'
import branchesEN from './pages/branches/locale/en.json'
import branchesAR from './pages/branches/locale/ar.json'
import loyaltyProgramEN from './pages/loyalty-program/locale/en.json'
import loyaltyProgramAR from './pages/loyalty-program/locale/ar.json'
import customerRewardsEN from './pages/rewards/locale/en.json'
import customerRewardsAR from './pages/rewards/locale/ar.json'
import settingsAR from './pages/settings/locale/ar.json'
import settingsEN from './pages/settings/locale/en.json'
import profileSettingsAR from './pages/profile-settings/locale/ar.json'
import profileSettingsEN from './pages/profile-settings/locale/en.json'
import accountSettingsEN from './pages/account-settings/locale/en.json'
import accountSettingsAR from './pages/account-settings/locale/ar.json'
import walletEN from './pages/wallet/locale/en.json'
import walletAR from './pages/wallet/locale/ar.json'
import apiMessagesEN from './locales/en.json'
import apiMessagesAR from './locales/ar.json'
import foodicsSuccessEN from './pages/foodics-success/locale/en.json'
import foodicsSuccessAR from './pages/foodics-success/locale/ar.json'
import productsEN from './pages/products/locale/en.json'
import productsAR from './pages/products/locale/ar.json'
import integrationEN from './pages/integration/locale/en.json'
import integrationAR from './pages/integration/locale/ar.json'
import campaignsEN from './pages/campaigns/locale/en.json'
import campaignsAR from './pages/campaigns/locale/ar.json'
import onboardingEN from './pages/onboarding/locale/en.json'
import onboardingAR from './pages/onboarding/locale/ar.json'

// Namspaces
const resources = {
  en: {
    menu: menuEN,
    footer: footerEN,
    dashboard: dashboardEN,
    component: componentEN,
    customers: customersEN,
    customerPage: customerPageEN,
    feedbacks: feedbacksEN,
    transactions: transactionsEN,
    branches: branchesEN,
    loyaltyProgram: loyaltyProgramEN,
    customerRewards: customerRewardsEN,
    settings: settingsEN,
    accountSettings: accountSettingsEN,
    profileSettings: profileSettingsEN,
    wallet: walletEN,
    apiMessages: apiMessagesEN,
    foodics: foodicsSuccessEN,
    products: productsEN,
    integration: integrationEN,
    campaigns: campaignsEN,
    onboarding: onboardingEN,
  },
  ar: {
    menu: menuAR,
    footer: footerAR,
    dashboard: dashboardAR,
    component: componentAR,
    customers: customersAR,
    customerPage: customerPageAR,
    feedbacks: feedbacksAR,
    transactions: transactionsAR,
    branches: branchesAR,
    loyaltyProgram: loyaltyProgramAR,
    customerRewards: customerRewardsAR,
    settings: settingsAR,
    accountSettings: accountSettingsAR,
    profileSettings: profileSettingsAR,
    wallet: walletAR,
    apiMessages: apiMessagesAR,
    foodics: foodicsSuccessAR,
    products: productsAR,
    integration: integrationAR,
    campaigns: campaignsAR,
    onboarding: onboardingAR,
  },
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    supportedLngs: ['en', 'ar'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  })

export default i18n
