import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import i18n from 'i18next'
import {
  getBusiness,
  updateBusiness,
  getTeam,
  addMember,
  deleteMember,
  updateMember,
  updateMemberPassword,
} from '../service'
import actions from './actions'

export function* GET_BUSINESS({ payload }) {
  yield put({
    type: 'business/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(getBusiness, payload)
  if (data.status) {
    const business = data.data
    yield put({
      type: 'business/SET_STATE',
      payload: {
        business,
        isOnboardingComplete: business.isOnboardingComplete,
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'business/SET_STATE',
      payload: {
        business: null,
        loading: false,
      },
    })
  }
}

export function* UPDATE_BUSINESS({ payload }) {
  yield put({
    type: 'business/SET_STATE',
    payload: {
      updating: true,
    },
  })
  const data = yield call(updateBusiness, payload)
  if (data.status) {
    yield put({
      type: 'business/SET_STATE',
      payload: {
        updating: false,
      },
    })
    yield put({
      type: 'business/GET_BUSINESS',
      payload: {
        businessId: payload.businessId,
      },
    })
    if (!payload.isOnboardingComplete) {
      notification.success({
        message: 200,
        description: i18n.t('settings:notification.businessUpdateTrue'),
        duration: 3,
      })
    }
  } else {
    yield put({
      type: 'business/SET_STATE',
      payload: {
        updating: false,
      },
    })
    notification.warning({
      message: 500,
      description: i18n.t('settings:notification.businessUpdateFalse'),
      duration: 5,
    })
  }
}

// TEAM

export function* ADD_MEMBER({ payload }) {
  yield put({
    type: 'business/SET_STATE',
    payload: {
      loadingMembers: true,
    },
  })
  const data = yield call(addMember, payload)
  if (data.status === true) {
    yield put({
      type: 'business/GET_TEAM',
      payload: {
        businessId: payload.businessId,
      },
    })
  } else {
    yield put({
      type: 'business/SET_STATE',
      payload: {
        loadingMembers: false,
      },
    })
  }
}

export function* UPDATE_MEMBER({ payload }) {
  yield put({
    type: 'business/SET_STATE',
    payload: {
      loadingMembers: true,
    },
  })
  const response = yield call(updateMember, payload)
  if (response.status) {
    yield put({
      type: 'business/GET_TEAM',
      payload: {
        businessId: payload.businessId,
      },
    })

    notification.success({
      message: 200,
      description: i18n.t('settings:notification.memberUpdateTrue'),
      duration: 3,
    })
  } else {
    notification.warning({
      message: 500,
      description: i18n.t('settings:notification.memberUpdateFalse'),
      duration: 3,
    })
  }
}

export function* GET_TEAM({ payload }) {
  yield put({
    type: 'business/SET_STATE',
    payload: {
      loadingMembers: true,
    },
  })
  const data = yield call(getTeam, payload)
  if (data.status) {
    const { team } = data.data
    yield put({
      type: 'business/SET_STATE',
      payload: {
        loadingMembers: false,
      },
    })
    yield put({
      type: 'business/SET_BUSINESS_ATTR',
      payload: {
        team,
        loadingMembers: false,
      },
    })
  } else {
    yield put({
      ype: 'business/SET_BUSINESS_ATTR',
      payload: {
        team: [],
        loadingMembers: false,
      },
    })
  }
}

export function* DELETE_MEMBER({ payload }) {
  yield put({
    type: 'business/SET_STATE',
    payload: {
      loadingMembers: true,
    },
  })
  const response = yield call(deleteMember, payload)
  if (response.status) {
    yield put({
      type: 'business/GET_TEAM',
      payload: {
        businessId: payload.businessId,
      },
    })

    notification.success({
      message: 200,
      description: i18n.t('settings:notification.memberDeleteTrue'),
      duration: 3,
    })
  } else {
    notification.warning({
      message: 500,
      description: i18n.t('settings:notification.memberDeleteFalse'),
      duration: 3,
    })
  }
}

// Cashiers
export function* GET_CASHIERS({ payload }) {
  yield put({
    type: 'business/SET_STATE',
    payload: {
      loadingCashiers: true,
    },
  })
  const response = yield call(getTeam, payload)
  if (response.status) {
    const { team } = response.data
    yield put({
      type: 'business/SET_STATE',
      payload: {
        cashiers: team,
        loadingCashiers: false,
      },
    })
  } else {
    yield put({
      ype: 'business/SET_STATE',
      payload: {
        cashiers: [],
        loadingCashiers: false,
      },
    })
  }
}

export function* DELETE_CASHIER({ payload }) {
  yield put({
    type: 'business/SET_STATE',
    payload: {
      loadingCashiers: true,
    },
  })
  const response = yield call(deleteMember, payload)
  if (response.status) {
    yield put({
      type: 'business/GET_CASHIERS',
      payload: {
        businessId: payload.businessId,
        cashier: true,
      },
    })

    notification.success({
      message: 200,
      description: i18n.t('branches:notification.cashierDeleteTrue'),
      duration: 3,
    })
  } else {
    yield put({
      type: 'business/SET_STATE',
      payload: {
        loadingCashiers: false,
      },
    })
    notification.warning({
      message: 500,
      description: i18n.t('branches:notification.cashierDeleteFalse'),
      duration: 3,
    })
  }
}

export function* ADD_CASHIER({ payload }) {
  yield put({
    type: 'business/SET_STATE',
    payload: {
      loadingCashiers: true,
    },
  })
  const data = yield call(addMember, payload)
  if (data.status === true) {
    yield put({
      type: 'business/GET_CASHIERS',
      payload: {
        businessId: payload.businessId,
        cashier: true,
      },
    })
    notification.success({
      message: 200,
      description: i18n.t('branches:notification.cashierAddTrue'),
      duration: 3,
    })
  } else {
    notification.warning({
      message: 500,
      description: i18n.t('branches:notification.cashierAddFalse'),
      duration: 3,
    })
    yield put({
      type: 'business/SET_STATE',
      payload: {
        loadingCashiers: false,
      },
    })
  }
}

export function* UPDATE_CASHIER({ payload }) {
  yield put({
    type: 'business/SET_STATE',
    payload: {
      loadingCashiers: true,
    },
  })
  const data = yield call(updateMember, payload)
  if (data.status) {
    yield put({
      type: 'business/GET_CASHIERS',
      payload: {
        businessId: payload.businessId,
        cashier: true,
      },
    })

    notification.success({
      message: 200,
      description: i18n.t('branches:notification.cashierUpdateTrue'),
      duration: 3,
    })
  } else {
    yield put({
      type: 'business/SET_STATE',
      payload: {
        loadingCashiers: false,
      },
    })
    notification.warning({
      message: 500,
      description: i18n.t('branches:notification.cashierUpdateFalse'),
      duration: 3,
    })
  }
}

export function* UPDATE_CASHIER_PASSWORD({ payload }) {
  yield put({
    type: 'business/SET_STATE',
    payload: {
      loadingCashiers: true,
    },
  })
  const data = yield call(updateMemberPassword, payload)
  if (data.status) {
    yield put({
      type: 'business/SET_STATE',
      payload: {
        loadingCashiers: false,
      },
    })
    notification.success({
      message: 200,
      description: i18n.t('branches:notification.cashierUpdateTrue'),
      duration: 3,
    })
  } else {
    yield put({
      type: 'business/SET_STATE',
      payload: {
        loadingCashiers: false,
      },
    })
    notification.warning({
      message: 500,
      description: i18n.t('branches:notification.cashierUpdateFalse'),
      duration: 3,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_BUSINESS, GET_BUSINESS),
    takeEvery(actions.UPDATE_BUSINESS, UPDATE_BUSINESS),
    takeEvery(actions.ADD_MEMBER, ADD_MEMBER),
    takeEvery(actions.DELETE_MEMBER, DELETE_MEMBER),
    takeEvery(actions.UPDATE_MEMBER, UPDATE_MEMBER),
    takeEvery(actions.GET_TEAM, GET_TEAM),
    takeEvery(actions.GET_CASHIERS, GET_CASHIERS),
    takeEvery(actions.DELETE_CASHIER, DELETE_CASHIER),
    takeEvery(actions.UPDATE_CASHIER, UPDATE_CASHIER),
    takeEvery(actions.UPDATE_CASHIER_PASSWORD, UPDATE_CASHIER_PASSWORD),
    takeEvery(actions.ADD_CASHIER, ADD_CASHIER),
  ])
}
